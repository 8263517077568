import React, {useEffect, useState } from "react";
 
 import {TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
import { useNavigate } from 'react-router-dom';

import {    
    Allowcustomers_view ,
    Allowcustomers_add ,
    Allowcustomers_edit ,
    Allowcustomers_delete 
} from './../../Permissions.js';

 

const Adduser = () => {

  const navigate = useNavigate();
  
const[userid] = useState(UrlSplitter(4));

const[fullname, setFullname] = useState(""); 
const[username, setUsername] = useState(""); 
const[email, setEmail] = useState(""); 
const[password, setPassword] = useState(""); 
const[confirmpassword, setConfirmPassword] = useState(""); 
const[status, setStatus] = useState(1); 



const [fullnamereqerror, setfullnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");

const [usernamereqerror, setusernamereqerror] = useState("");
const [passwordreqerror, setpasswordreqerror] = useState("");
const [confirmpasswordreqerror, setconfirmpasswordreqerror] = useState("");

const [emailexistserror, setemailexistserror] = useState("");
const [usernameexistserror, setusernameexistserror] = useState("");

const [emailexistsvalidation , setemailexistsvalidation] = useState("0");
const [usernameexistsvalidation , setusernameexistsvalidation] = useState("0");


const [emailvaliderror, setemailvaliderror] = useState("");




 
 const Getuser = async (e) => {
  
  try{
     var postdata={_id:userid};

     const response = await fetch(Url+"getusers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              
              var userdata=data.data;

                setFullname(userdata.fullname);
                setUsername(userdata.username);
                setEmail(userdata.email);
                 setStatus(userdata.status);
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      //
  }

 } 


const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        case 'fullname':
              setFullname('');
              (TextSpaceonlyValidation(value) == true ) ?  setFullname(value) : setfullnamereqerror('');
             
             
            break;
        case 'email':
            setEmail(value);
            setemailreqerror('');
            break;
        case 'username':
            setUsername(value);
             setusernamereqerror('');
            break;
        case 'password':
            setPassword(value);
            setpasswordreqerror('')
            break;  
        case 'confirmpassword':
            setConfirmPassword(value);
            setconfirmpasswordreqerror('')
            break;  
     
         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }
 


 
  const Updateuser = async (e) => {

    setfullnamereqerror('');
    setusernamereqerror('');
    setemailreqerror('');

 
    var validationerror = 0;
 
    if(checkEmptyUndefined(fullname) == false){
        setfullnamereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(username) == false){
        setusernamereqerror(requiredErrormessage)
        validationerror=1;
    }  
    if(checkEmptyUndefined(email) == false){

        setemailreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(email) == true && validEmailRegex.test(email) == false){
        setemailreqerror("Invalid Email")
        validationerror=1; 
    }




    if(typeof userid == 'undefined' || userid == ''){

         if(checkEmptyUndefined(password) == false){

            setpasswordreqerror(requiredErrormessage)
            validationerror=1;
        }

        if(checkEmptyUndefined(confirmpassword) == false){

            setconfirmpasswordreqerror(requiredErrormessage)
            validationerror=1;
        }
          if(password != confirmpassword){
             setconfirmpasswordreqerror("Password and Confirm Password Should match")
             validationerror=1;
        }




    }else{

        if(password != ''){

        if(password != confirmpassword){
             setconfirmpasswordreqerror("Password and Confirm Password Should match")
             validationerror=1;
        }


    }
    }
    


 
     if(validationerror == 0){ 

 
       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;
        
      var postdata={_id:userid ,fullname :fullname,username:username,email:email, status:statusupdate , password:password};
 
      const response = await fetch(Url+"updateuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)
                                   navigate('/umy-admin-portal/users');

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };


    
useEffect(() => {
    Getuser(userid);

if(typeof userid != 'undefined' && userid !=''){
    checkpermissionredirect(Allowcustomers_edit)
}else{
    checkpermissionredirect(Allowcustomers_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Customer":"Add Customer"} />

         <Sidebar activePage="Customers"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Customer":"Add Customer"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Customer":"Add Customer"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={fullname}  type="text" name="fullname" placeholder="Name"  
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{fullnamereqerror}</p>

                        </div>
                        <div className="col-md-4">
                            <label>Username <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="username" placeholder="Username" value={username} 
                onChange={(e) => handleUserInput(e)}
                required/>
                    <p className="req-error-message">{usernamereqerror}</p>

                        </div>
                         
                        <div className="col-md-4">
                            <label>Email <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="email" placeholder="Email" value={email} 
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{emailreqerror}</p>

                        </div>


                        <div className="col-md-4">
                            <label>Password</label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="password" placeholder="Password" value={password} 
                onChange={(e) => handleUserInput(e)}
                required/>
                         <p className="req-error-message">{passwordreqerror}</p>

                        </div>

                        <div className="col-md-4">
                            <label>Confirm Password</label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="confirmpassword" placeholder="Confirm Password" value={confirmpassword} 
                onChange={(e) => handleUserInput(e)}
                required/>
                                                    <p className="req-error-message">{confirmpasswordreqerror}</p>


                        </div>

                       
                        
                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                       
                        <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updateuser}>Submit</a>
                         </div>
                           

                       
                        


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Adduser;