import React, { useState ,useEffect} from "react";
 
 import { Link, useNavigate} from "react-router-dom";

 import {UrlSplitter,TextSpaceonlyValidation, MobileNumberValidation,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername , validEmailRegex } from '../Frontapi.js'; 

import {Lang} from "../Languages.js"

import {Blogdetailurl} from "../Siteurls.js"


import Header from "../includes/Header";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";




 

 



 
const Blogdetail = () => {
  const navigate = useNavigate();

 
const [blogdata,setBlogdata]= useState([])
 const[url] = useState(UrlSplitter(2));

const [title,setTitle]=useState('')
const [image,setImage]=useState('')
const [content,setContent]=useState('')


 const GetBlogdetail = async (  ) => {
   
 

      var postdata={ url:url };

        const response = await fetch(Url+"getblogu", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var data=data.data
             setTitle(data.title)
             setImage(data.image)
             setContent(data.content)

          
          } else{
                 
          }

           
       })


 
  };

 


   useEffect(() => {
  GetBlogdetail()
   }, []);


  return (

      <div id="app">

      <Header />

      <Navbar />



          <div className="main-section"> 

    <div className="main-container"> 

              <h1 className="site-head-main-title ">{title}  </h1>




 
    <div className="col-md-12 col-lg-12 col-sm-12 row">

                      
        <div className="col-md-9 col-lg-9 col-sm-9  col-12 ">

                      
        <img src={image} />


              <div dangerouslySetInnerHTML={{__html:  content}} />

      
         
    </div>


            <div className="col-md-3 col-lg-3 col-sm-3 col-12 ">

            <h2> {Lang('Recent Blog')}</h2>

            


            </div>

      
         
    </div> 

     

              </div> 


              </div>

 


      <Footer />

 
   </div>
 
  );
};
export default Blogdetail;