import React, { useEffect, useState } from "react";
import { Getblogimage, baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../Api.js';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";
import Footer from "../includes/Footer";

const Myjobs = () => {
  const [jobList, setJobList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPages, setnPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([1]);

  const goToNextPage = () => {
    if (currentPage < nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToPage = (value) => {
    setCurrentPage(value);
  };

  const GetJobs = async (value) => {
    value = value || currentPage;
  
    const vendorId = localStorage.getItem('vuserId');
    console.log("Vendor ID from localStorage:", vendorId);  
  
    if (!vendorId) {
        alert('Vendor ID not found in localStorage!');
        return;
    }
  
    const postData = {
        admin: 1,
        currentpage: value,
        perpage: recordsPerPage,
        vendorid: vendorId 
    };
  
    try {
        const response = await fetch(Url + "vendor-portal-jobs", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata: postData }),
        });
        const data = await response.json();
        console.log("API response data:", data);

        console.log("Response data:", data);
  
        if (data && data.data) {
            let jobData = data.data;
  
            jobData = jobData.map(job => {
                const acceptedJob = localStorage.getItem(`acceptDisabled_${job._id}`);
                const rejectedJob = localStorage.getItem(`rejectDisabled_${job._id}`);
  
                return {
                    ...job,
                    acceptDisabled: acceptedJob === 'true',
                    rejectDisabled: rejectedJob === 'true',
                    statusText: acceptedJob === 'true' ? 'Accepted' : (rejectedJob === 'true' ? 'Rejected' : '')
                };
            });
  
            setJobList(jobData);
  
            if (data.count > 1) {
                const totalPages = Math.ceil(data.count / recordsPerPage);
                setnPages(totalPages);
  
                const newPageNumbers = [...Array(totalPages).keys()].map(i => i + 1);
                setPageNumbers(newPageNumbers);
            }
        }
    } catch (error) {
        console.error("Error fetching jobs:", error);
    }
  };
  
  
  const DeleteConfirm = async (id) => {
    if (window.confirm("Are you sure?")) {
      const postData = { id };

      try {
        const response = await fetch(Url + "deletejob", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ postdata: postData }),
        });
        const data = await response.json();

        if (data) {
          DeleteNotify();
        } else {
          DeleteNotify(data.message);
        }

        GetJobs(currentPage);
      } catch (error) {
        console.error("Error deleting job:", error);
      }
    }
  };

  const acceptJob = async (jobId) => {
    const postData = { jobid: jobId, status: 'accepted' };
    try {
      const response = await fetch(Url + "vendor-portal-acceptjob", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      const data = await response.json();
      if (data) {
        alert("Job Accepted");
        
        localStorage.setItem(`acceptDisabled_${jobId}`, 'true');
        localStorage.setItem(`rejectDisabled_${jobId}`, 'true');

        setJobList(prevList =>
          prevList.map(job =>
            job._id === jobId ? { ...job, status: 1, acceptDisabled: true, rejectDisabled: true, statusText: 'Accepted' } : job
          )
        );
      } else {
        alert("Failed to accept job");
      }
    } catch (error) {
      console.error("Error accepting job:", error);
    }
  };

  const rejectJob = async (jobId) => {
    const postData = { jobid: jobId, status: 'rejected' };
    try {
      const response = await fetch(Url + "vendor-portal-rejectjob", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      const data = await response.json();
      if (data) {
        alert("Job Rejected");
        localStorage.setItem(`acceptDisabled_${jobId}`, 'true');
        localStorage.setItem(`rejectDisabled_${jobId}`, 'true');

        setJobList(prevList =>
          prevList.map(job =>
            job._id === jobId ? { ...job, status: 3, acceptDisabled: true, rejectDisabled: true, statusText: 'Rejected' } : job
          )
        );
      } else {
        alert("Failed to reject job");
      }
    } catch (error) {
      console.error("Error rejecting job:", error);
    }
  };

  useEffect(() => {
    GetJobs(currentPage);
  }, [currentPage]);

  return (
    <div id="app">
      <Header title="My Jobs" />
      <Sidebar activePage="Myjobs" activeChildPage="List" />
      <div id="main">
        <Navbar />
        <div className="main-content container-fluid">
          <Pagetitle activePage="Myjobs" />

          <section className="section">
            <div className="card">
              <div className="card-header">
                <span>My Jobs</span>
              </div>
              <div className="card-body">
                <table className="table table-striped" id="table1">
                  <thead>
                    <tr>
                      <th>Service Date</th>
                      <th>Customer Name</th>
                      <th>Address</th>
                      <th>Job Status</th>
                    </tr>
                  </thead>

                  {jobList.length > 0 ? (
                    <tbody>
                      {jobList.map((job) => (
                        <tr key={job._id}>
                          <td>{job.servicedate}</td>
                          <td>{job.address}</td>
                          <td>{job.customerName}</td>
                          <td className="table-action-sections" style={{ display: 'flex', gap: '8px' }}>
                            <button onClick={() => acceptJob(job._id)} className="btn btn-success btn-sm" disabled={job.acceptDisabled}>
                              {job.statusText === 'Accepted' ? 'Accepted' : 'Accept'}
                            </button>
                            <button onClick={() => rejectJob(job._id)} className="btn btn-danger btn-sm" disabled={job.rejectDisabled}>
                              {job.statusText === 'Rejected' ? 'Rejected' : 'Reject'}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="4" align="center">No Records Found</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            {nPages > 1 && (
              <nav>
                <ul className="pagination pagination-primary justify-content-center">
                  <li className="page-item prev-paging-single">
                    <a className="page-link" onClick={goToPrevPage} href="#">Previous</a>
                  </li>
                  {pageNumbers.map((pgNumber) => (
                    <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? "active" : ""}`}>
                      <a onClick={() => goToPage(pgNumber)} className="page-link" href="#"> {pgNumber} </a>
                    </li>
                  ))}
                  <li className="page-item paging-next-single">
                    <a className="page-link" onClick={goToNextPage} href="#">Next</a>
                  </li>
                </ul>
              </nav>
            )}
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Myjobs;
