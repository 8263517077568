import React, { useEffect, useState } from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import { baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../../Api.js';
import { Getbannervideo } from "../../../frontend/GetDynamicImages.js";

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";
import {    
  Allowbannerimage_view ,
  Allowbannerimage_add ,
  Allowbannerimage_edit,
  Allowbannerimage_delete
} from './../../Permissions.js';

const Videolist = () => {
    const [videoList, setVideoList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [nPages, setnPages] = useState(1);
    const [pageNumbers, setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

    const goToNextPage = () => {
        if(currentPage !== nPages) {
            setCurrentPage(currentPage + 1);
            GetVideo();
        }
    }

    const goToPrevPage = () => {
        if(currentPage !== nPages) {
            setCurrentPage(currentPage - 1);
            GetVideo();
        }
    }

    const goToPage = (value) => {
        setCurrentPage(value);
        GetVideo(value);
    }

    const GetVideo = async (value) => {
        value = (typeof value !== 'undefined') ? value : currentPage;
        var postdata = { 'currentpage': value, 'perpage': recordsPerPage };

        const response = await fetch(Url + "getbannervideo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),
        }).then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    var videoData = data.data;

                    Object.keys(videoData).forEach(function (key) {
                        (async function () {
                            videoData[key].image = await Getbannervideo(videoData[key].image);
                        })();
                    });

                    setVideoList(videoData);

                    if (data.count > 1) {
                        setpageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1));
                    }
                }
            });
    };

    const DeleteConfirm = async (id) => {
        if (window.confirm("Are you sure?")) {
            var postdata = { 'id': id };
            const response = await fetch(Url + "deletebannervideo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ postdata }),
            }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        DeleteNotify();
                    } else {
                        DeleteNotify(data.message);
                    }

                    GetVideo();
                });
        }
    };

    useEffect(() => {
        GetVideo();
        checkpermissionredirect(Allowbannerimage_view);
    }, []);

    return (
        <div id="app">
            <Header title="Video List" />

            <Sidebar activePage="Videos" activeChildPage="List" />
            <div id="main">
                <Navbar />

                <div className="main-content container-fluid">
                    <Pagetitle activePage="Videos" />

                    <section className="section">
                        <div className="card">
                            <div className="card-header">
                                <span>Videos</span>

                                {(Allowbannerimage_add === 1) ?
                                    <div className="pull-right"><a href={baseUrl + 'videos/add'} className="btn btn-success me-1 mb-1">Add Video</a></div>
                                    : ''}
                            </div>
                            <div className="card-body">
                                <table className='table table-striped' id="table1">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Image</th>
                                            <th>Link</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    {videoList.length > 0 ?
                                        <tbody>
                                            {videoList.map((video) => (
                                                <tr key={video._id}>
                                                    <td>{video.name}</td>

                                                    <td><img src={video.image} className="img-responsive w-100px" alt="video-preview" /></td>
                                                    <td><img src={video.link} className="img-responsive w-100px" alt="video-preview" /></td>

                                                    <td>
                                                        {(video.status === 1) ?
                                                            <span className="badge bg-success">Active</span> :
                                                            <span className="badge bg-danger">InActive</span>
                                                        }
                                                    </td>
                                                    <td className="table-action-sections">
                                                      
                                                        {/* {(Allowbannerimage_edit === 1) ?
                                                            <Link to={baseUrl + 'blogcategory/edit/' + video._id} className="fa-edit-btn">
                                                                <i className="fa fa-pencil"></i>
                                                            </Link> : ''}

                                                        {(Allowbannerimage_delete === 1) ?
                                                            <a onClick={() => DeleteConfirm(video._id)} className="fa-del-btn">
                                                                <i className="fa fa-trash"></i>
                                                            </a> : ''} */}

                                                            
                            {(Allowbannerimage_edit == 1) ?
                            <Link to={baseUrl+'bannervideo/edit/' + video._id} className="fa-edit-btn" > <i className="fa fa-pencil"></i></Link> : ''}

                             {(Allowbannerimage_delete == 1) ?
                            <a    onClick={() => DeleteConfirm(video._id)}   className="fa-del-btn"   ><i className="fa fa-trash"></i> </a>
                            : ''}

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody> : <tbody><tr><td colSpan="5" align="center">No Records Found</td></tr></tbody>}

                                </table>
                            </div>
                        </div>

                        {pageNumbers.length > 1 ?
                            <nav>
                                <ul className='pagination pagination-primary justify-content-center'>
                                    <li className="page-item prev-paging-single">
                                        <a className="page-link" onClick={goToPrevPage} href='#'>
                                            Previous
                                        </a>
                                    </li>
                                    {pageNumbers.map(pgNumber => (
                                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                                            <a onClick={(e) => goToPage(pgNumber)} className='page-link' href='#'>
                                                {pgNumber}
                                            </a>
                                        </li>
                                    ))}
                                    <li className="page-item paging-next-single">
                                        <a className="page-link" onClick={goToNextPage} href='#'>
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav> : ''}

                    </section>

                    <Footer />
                </div>

            </div>

        </div>
    );
};

export default Videolist;
