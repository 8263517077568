import React, { useState, useEffect, useSyncExternalStore } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Siteinfo, TextSpaceonlyValidation, Siteemail, Sitephone, Siteaddress, MobileNumberValidation, requiredErrormessage, frontUrl, Notifyalert, Url, Redirectlogin, AmigouserId, Amigousername, validEmailRegex } from '../Frontapi.js';

import { Lang } from "../Languages.js"


import Header from '../includes/Header';
import Navbar from '../includes/Navbar';
import './Contactus.css'

import Footer from '../includes/Footer';

const Contactus = () => {

  var SiteData = Siteinfo[0]['sitedata']


  const contactemail = SiteData['email'];
  const contactphone = SiteData['phone'];
  const contactaddress = SiteData['address'] + ',' + SiteData['city'] + ',' + SiteData['state'] + ',' + SiteData['country'] + ',' + SiteData['postalcode'];




  const [name, setName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [errors, setErrors] = useState({});

  const [error, setError] = useState('');

  const [message, setMessage] = useState("");

  const [btndisabled, setbtndisabled] = useState('btn-disabled');

  const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');





  const [emailvaliderror, setemailvaliderror] = useState("");



  const handleContactInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setContactEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'message':
        setContactMessage(value);
        break;
      default:
        break;
    }
  };


  // const handleContactformSubmit = (event) => {
  //   event.preventDefault();

  //   let formErrors = {};


  //   if (name.trim() === '') {
  //     formErrors.name = 'Please enter your name.';
  //   }
  //   if (contactEmail.trim() === '') {
  //     formErrors.email = 'Please enter a valid email address.';
  //   }
  //   if (phone.trim() === '') {
  //     formErrors.phone = 'Please enter your phone number.';
  //   }
  //   if (subject.trim() === '') {
  //     formErrors.subject = 'Please enter a subject.';
  //   }
  //   if (contactMessage.trim() === '') {
  //     formErrors.message = 'Please enter your message.';
  //   }


  //   if (Object.keys(formErrors).length > 0) {
  //     setErrors(formErrors);
  //     return;
  //   }


  //   setErrors({});

  //   const postData = {
  //     postdata: {
  //       name: name,
  //       email: contactEmail,
  //       mobile: phone,
  //       subject: subject,
  //       message: contactMessage,
  //     }
  //   };


  //   fetch('http://localhost:5090/create-new-inquiry', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(postData),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.success) {

  //         alert(result.message);

  //         setName('');
  //         setContactEmail('');
  //         setPhone('');
  //         setSubject('');
  //         setContactMessage('');
  //         setErrors({});
  //       } else {

  //         alert(result.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       alert('An error occurred. Please try again later.');
  //     });
  // };

  const handleContactformSubmit = (event) => {
    event.preventDefault();

    let formErrors = {};

    
    if (name.trim() === '') {
        formErrors.name = 'Please enter your name.';
    }

    
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (contactEmail.trim() === '') {
        formErrors.email = 'Please enter your email address.';
    } else if (!emailPattern.test(contactEmail)) {
        formErrors.email = 'Please enter a valid email address.';
    }

    
    const phonePattern = /^[0-9]{10}$/;
    if (phone.trim() === '') {
        formErrors.phone = 'Please enter your phone number.';
    } else if (!phonePattern.test(phone)) {
        formErrors.phone = 'Please enter a valid phone number.';
    }

    
    if (subject.trim() === '') {
        formErrors.subject = 'Please enter a subject.';
    }

    if (contactMessage.trim() === '') {
        formErrors.message = 'Please enter your message.';
    }


    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }


    setErrors({});

    
    const postData = {
        postdata: {
            name: name,
            email: contactEmail,
            mobile: phone,
            subject: subject,
            message: contactMessage,
        }
    };

    
    fetch('http://localhost:5090/create-new-inquiry', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    })
        .then((response) => response.json())
        .then((result) => {
            if (result.success) {
                alert(result.message);

                setName('');
                setContactEmail('');
                setPhone('');
                setSubject('');
                setContactMessage('');
                setErrors({});
            } else {
                alert(result.message);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        });
};



  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   setError('');


  //   if (email.trim() === '') {
  //     setError('Please enter a valid email address.');
  //     return;
  //   }

  //   const postData = {
  //     postdata: {
  //       email: email,
  //     },
  //   };

  //   try {
  //     const response = await fetch('http://localhost:5090/subscribe-user', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(postData),
  //     });

  //     const result = await response.json();

  //     if (response.ok) {
  //       if (result.success) {

  //         setEmail('');
  //         setError('');
  //         alert(result.message);
  //       } else {
  //         setError(result.message);
  //       }
  //     } else {
  //       setError('An error occurred. Please try again later.');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setError('An error occurred. Please try again later.');
  //   }
  // };


  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (email.trim() === '') {
        setError('Please Enter your email address.');
        return;
    }

    if (!emailRegex.test(email)) {
        setError('Please enter a valid email address.');
        return;
    }

    const postData = {
        postdata: {
            email: email,
        },
    };

    try {
        // const response = await fetch('http://localhost:5090/subscribe-user', {
          const response = await fetch(Url + 'subscribe-user', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        });

        const result = await response.json();

        if (response.ok) {
            if (result.success) {
                setEmail('');
                setError('');
                alert(result.message);
            } else {
                setError(result.message);
            }
        } else {
            setError('An error occurred. Please try again later.');
        }
    } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again later.');
    }
};



  return (
    <div >
      <Header pageInfo="Contactus" />



      <div className="container top-bottom contact-form">
        <div className="center-align">
          <h3 className="section-title">Contact Us</h3>
          <h2 className="section-title">We're Here to Help You!</h2>
        </div>

        {/* Contact Info Column - Left Side */}


        <div className="col-md-12 col-sm-12 col-xs-12 banner">
          <div className="col-md-6 col-sm-6 col-xs-12">

            <ul className="contact-us-details">
              <li>
                <div className="media">
                  <div className="media-left left_alignment">
                    <em className="fa fa-map-marker">&nbsp;</em>
                  </div>
                  <div className="media-body">
                    <h5 className="icons_contact_page">Address</h5>
                    <p className="icons_contact_page_text">{contactaddress}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="media">
                  <div className="media-left left_alignment">
                    <em className="fa fa-phone">&nbsp;</em>
                  </div>
                  <div className="media-body">
                    <h5 className="icons_contact_page">Let's Talk</h5>
                    <p className="icons_contact_page_text">{contactphone}</p>

                  </div>
                </div>
              </li>
              <li>
                <div className="media">
                  <div className="media-left left_alignment">
                    <em className="fa fa-envelope">&nbsp;</em>
                  </div>
                  <div className="media-body">
                    <h5 className="icons_contact_page">General Support</h5>
                    <p className="icons_contact_page_text">{contactemail}</p>
                  </div>
                </div>
              </li>
            </ul>

          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 banner-img">
            <form method="post" action="contact" class="contact-us-form" novalidate="novalidate">
              <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12 field-row paddingzero">
                <div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
                  <label class="field_title" for="name">Name <span class="req">*</span></label>
                  <div class="form_input">
                    <input name="name" className={`form-control homepage_contact_input ${errors.name ? 'is-invalid' : ''}`} type="text" value={name} onChange={handleContactInputChange} placeholder="Your Name" aria-label="name" />
                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}	                </div>
                </div>
                <div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
                  <label class="field_title" for="email">Email ID <span class="req">*</span></label>
                  <div class="form_input">
                    <input className={`form-control homepage_contact_input ${errors.email ? 'is-invalid' : ''}`}
                      autocomplete="off" type="text" name="email" class="form-control" id="email" placeholder="Email ID" value={contactEmail}
                      onChange={handleContactInputChange} />

                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}	                </div>
                </div>
              </div>


              <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12 field-row paddingzero">
                <div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
                  <label class="field_title" for="name">Phone Number <span class="req">*</span></label>
                  <div class="form_input">
                    <input name="phone" className={`form-control homepage_contact_input ${errors.phone ? 'is-invalid' : ''}`} type="text" value={phone} onChange={handleContactInputChange} placeholder="Phone Number" aria-label="phone" />
                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}	                </div>
                </div>
                <div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
                  <label class="field_title" for="email">Subject <span class="req">*</span></label>
                  <div class="form_input">
                    <input name="subject" className={`form-control homepage_contact_input ${errors.subject ? 'is-invalid' : ''}`} type="text" value={subject} onChange={handleContactInputChange} placeholder="Subject" aria-label="subject" />
                    {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}	                </div>
                </div>
              </div>


              <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12 field-row paddingzero">
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12">
                  <label class="field_title" for="user_msg">Message <span class="req">*</span></label>
                  <div class="form_input">
                    <textarea name="message" className={`form-control homepage_contact_input ${errors.message ? 'is-invalid' : ''}`} value={contactMessage} onChange={handleContactInputChange} placeholder="Your Message" rows={6} aria-label="message" ></textarea>
                    {errors.message && <div className="invalid-feedback">{errors.message}</div>}	                  </div>
                </div>
              </div>
              {/* <div class="col-sm-12 col-md-12 col-xs-12  field-row text-center">
            <button type="submit" class="contact_buttons" tabindex="10" onClick={handleContactformSubmit}><span className="contacts">Contact Us</span></button>
          </div> */}

              <div className="col-sm-12 col-md-12 col-xs-12 form-group submit-button-container text-center">
                <button className="contactus_submit_button" onClick={handleContactformSubmit}>
                  Contact Us
                </button>
              </div>
            </form>

          </div>
        </div>





        {/*  */}

      </div>


      <div className="container subscribe_container">

        <div>
          <p className="subscribe_para">Subscribe to newsletter for</p>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 banner paddingzero">
          <div className="col-md-6 col-sm-6 col-xs-12 paddingzero inputpadding">
            <input type="text" className="form-control subscribe_input" placeholder="Enter your email" aria-label="Your placeholder text" value={email} onChange={handleInputChange} />

            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 banner-img sub_button">
            <button className="subscribe_button" type="button" onClick={handleSubmit}>
              Subscribe
            </button>
          </div>
        </div>
      </div>


      <Footer />
    </div>


  );
};
export default Contactus;