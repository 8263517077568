import React, { useState } from 'react';
import '../auth/Register.css';
import Header from "../../frontend/includes/Header";
// import Navbar from "../includes/Navbar";
import Footer from "../../frontend/includes/Footer";

function Register() {
    const [fullname, setFullname] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [address, setAddress] = useState('');
    const [area, setArea] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postcode, setPostcode] = useState('');
    const [preferences, setPreferences] = useState('');

    const [accountholdername, setAccountholdername] = useState('');
    const [bankname, setBankname] = useState('');
    const [taxnumber, setTaxnumber] = useState('');
    const [bankswiftcode, setBankswiftcode] = useState('');
    const [bankaccountnumber, setBankaccountnumber] = useState('');
    const [addressproof, setAddressproof] = useState(null);
    const [idproof, setIdproof] = useState(null);
    const [companyproof, setCompanyproof] = useState(null);
    const [kycapproved, setKycapproved] = useState("");

    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleNext = () => {
        const newErrors = {};

        if (step === 1) {
            if (!fullname) newErrors.fullname = 'Full Name is required';
            if (!username) newErrors.username = 'Username is required';
            if (!email) newErrors.email = 'Email is required';
            if (!phonenumber) newErrors.phonenumber = 'Phone Number is required';
            if (!password) newErrors.password = 'Password is required';
            if (password !== confirmpassword) newErrors.confirmpassword = 'Passwords do not match';
        }

        if (step === 2) {
            if (!address) newErrors.address = 'Address is required';
            if (!area) newErrors.area = 'Area is required';
            if (!city) newErrors.city = 'City is required';
            if (!state) newErrors.state = 'State is required';
            if (!country) newErrors.country = 'Country is required';
            if (!postcode) newErrors.postcode = 'Postcode is required';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setStep(prevStep => prevStep + 1);
        }
    };

    const handlePrev = () => {
        setStep(prevStep => prevStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
   
        setErrors({});
        
        const newErrors = {};
        
   
        if (step === 1) {
            if (!fullname) newErrors.fullname = 'Full Name is required';
            if (!username) newErrors.username = 'Username is required';
            if (!email) newErrors.email = 'Email is required';
            if (!phonenumber) newErrors.phonenumber = 'Phone Number is required';
            if (!password) newErrors.password = 'Password is required';
            if (password !== confirmpassword) newErrors.confirmpassword = 'Passwords do not match';
        }
        
       
        if (step === 2) {
            if (!address) newErrors.address = 'Address is required';
            if (!area) newErrors.area = 'Area is required';
            if (!city) newErrors.city = 'City is required';
            if (!state) newErrors.state = 'State is required';
            if (!country) newErrors.country = 'Country is required';
            if (!postcode) newErrors.postcode = 'Postcode is required';
        }
        

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            if (step === 2) {
                const formData = new FormData();
                
              
                const postdata = {
                    fullname,
                    username,
                    email,
                    phonenumber,
                    password,
                    accountholdername,
                    bankname,
                    taxnumber,
                    bankswiftcode,
                    bankaccountnumber,
                 
                    address,
                    area,
                    city,
                    state,
                    country,
                    postcode,
                  
                    addressproof,
                    idproof,
                    companyproof,
                    kycapproved
                };
                
        
                formData.append('postdata', JSON.stringify(postdata));
    

                if (file) {
                    formData.append('profilepicture', file);
                }
                
                
                try {
                    const response = await fetch('http://localhost:5090/updatevendor', {
                        method: 'POST',
                        body: formData,
                    });
                    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    
                    const result = await response.json();
                    console.log('Response from API:', result);
                    
                    if (result.success) {
                        alert('Registration Successful!');
                        resetForm();
                    } else {
                        alert(`Error: ${result.message}`);
                    }
                } catch (error) {
                    console.error('Error during form submission:', error);
                    alert('Something went wrong, please try again.');
                }
            }
        }
    };
    

    const resetForm = () => {
        setFullname("");
        setUsername("");
        setEmail("");
        setPhonenumber("");
        setPassword("");
        setConfirmPassword("");
        setAddress("");
        setArea("");
        setCity("");
        setState("");
        setCountry("");
        setPostcode("");
        setPreferences("");
        setAccountholdername("");
        setBankname("");
        setTaxnumber("");
        setBankswiftcode("");
        setBankaccountnumber("");
        setAddressproof(null);
        setIdproof(null);
        setCompanyproof(null);
        setKycapproved("");
        setStep(1);
        setErrors({});
    };

    return (
        <div className='id'>
            {/* <Header/> */}
            <button className="back-button" onClick={() => window.history.back()}>← Back</button>
            <div className="register-container">
                <h2 className='create_account'>Create Account</h2>
                <form onSubmit={handleSubmit} className="register-form">
                    {/* Step 1: Personal Info */}
                    {step === 1 && (
                        <>
                            <div className="form-group">
                                <label className='register_label'>Full Name <span className="required">*</span></label>
                                <input 
                                    type="text" value={fullname} onChange={(e) => setFullname(e.target.value)} placeholder="Enter your full name" />
                                {errors.fullname && <span className="error-message">{errors.fullname}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Username <span className="required">*</span></label>
                                <input
                                    type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter your username" />
                                {errors.username && <span className="error-message">{errors.username}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Email <span className="required">*</span></label>
                                <input
                                    type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Phone Number <span className="required">*</span></label>
                                <input
                                    type="tel" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} placeholder="Enter your phone number" />
                                {errors.phonenumber && <span className="error-message">{errors.phonenumber}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Password <span className="required">*</span></label>
                                <input
                                    type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                                {errors.password && <span className="error-message">{errors.password}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Confirm Password <span className="required">*</span></label>
                                <input
                                    type="password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm your password" />
                                {errors.confirmpassword && <span className="error-message">{errors.confirmpassword}</span>}
                            </div>
                            <button type="button" className="next-btn" onClick={handleNext}>Next</button>
                        </>
                    )}

                    {/* Step 2: Address Info */}
                    {step === 2 && (
                        <>
                            <h3>Address Information</h3>
                            <div className="form-group">
                                <label className='register_label'>Address <span className="required">*</span></label>
                                <input 
                                    type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter your address" />
                                {errors.address && <span className="error-message">{errors.address}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Area <span className="required">*</span></label>
                                <input
                                    type="text" value={area} onChange={(e) => setArea(e.target.value)} placeholder="Enter your area" />
                                {errors.area && <span className="error-message">{errors.area}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>City <span className="required">*</span></label>
                                <input
                                    type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Enter your city" />
                                {errors.city && <span className="error-message">{errors.city}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>State <span className="required">*</span></label>
                                <input
                                    type="text" value={state} onChange={(e) => setState(e.target.value)} placeholder="Enter your state" />
                                {errors.state && <span className="error-message">{errors.state}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Country <span className="required">*</span></label>
                                <input
                                    type="text" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Enter your country" />
                                {errors.country && <span className="error-message">{errors.country}</span>}
                            </div>
                            <div className="form-group">
                                <label className='register_label'>Postcode <span className="required">*</span></label>
                                <input
                                    type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} placeholder="Enter your postcode" />
                                {errors.postcode && <span className="error-message">{errors.postcode}</span>}
                            </div>
                            <div className="form-buttons">
                                <button type="button" className="prev-btn" onClick={handlePrev}>Previous</button>
                                <button type="submit" className="submit-btn">Register</button>
                            </div>
                        </>
                    )}
                </form>
            </div>

            {/* <Footer/> */}

        </div>
    );
}

export default Register;



