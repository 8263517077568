import React, { useState, useEffect } from "react";

import { SiteLogo, baseUrl, Url, CommonNotify } from './../Api.js';

import { Link } from 'react-router-dom';

import {
   Allowdashboard_view,
   Allowsettings_view,
   Allowsettings_edit,
   Allowcustomers_view,
   Allowcustomers_add,
   Allowcustomers_edit,
   Allowcustomers_delete,
   Allowcms_view,
   Allowcms_edit,
   Allowcms_add,
   Allowcms_delete,


   Allowemailtemplates_view,
   Allowemailtemplates_edit,

   Alloworders_view,
   Alloworders_edit,
   Allowenquiries_view,


   Allowsubscribers_view,


   Allowstreams_view,
   Allowschools_view,
   Allowschools_add,
   Allowschools_edit,
   Allowschools_delete,

   Allowadminusers_view,
   Allowadminusers_add,
   Allowrolesview,

   Allowfaqs_view,
   Allowfaqs_add,
   Allowfaqgroups_view,
   Allowfaqgroups_add,

   Allowsliders_view,
   Allowsliders_add,

   Allowbannerimage_view,
   Allowbannerimage_add,

   Allowdashboard_add,

   Allowblog_view,
   Allowblog_add,


   Allowblogcategory_view,


   Allowlanguages_view,
   Allowlanguages_add,


   Allowcurrencies_view,
   Allowcurrencies_add,


   Allowplans_view,
   Allowplans_add,

   Allowcategories_view,
   Allowcategories_add,

   Allowsubcategories_view,
   Allowsubcategories_add,


   Allowservices_view,
   Allowservices_add,

   Allowbookings_view,
   Allowreviews_view,


   Allowvendors_view,
   Allowvendors_add,
   Allowvendors_edit,
   Allowvendors_delete,


   Allownotifications_view,
   Allownotifications_add


} from './../Permissions.js';


const Sidebar = ({ activePage, activeChildPage }) => {


   const [showAdminuserMenu, setshowAdminuserMenu] = useState(0);

   const [showUserMenu, setshowUserMenu] = useState(0);
   const [showRoleMenu, setshowRoleMenu] = useState(0);

   const [showFaqMenu, setshowFaqMenu] = useState(0);
   const [showFaqgroupMenu, setshowFaqgroupMenu] = useState(0);

   const [showEmailTemplateMenu, setShowEmailTemplateMenu] = useState(0);

   const [showSliderMenu, setshowSliderMenu] = useState(0);

   const [showbannerMenu, setshowBannerMenu] = useState(0);

   const [showBlogMenu, setshowBlogMenu] = useState(0);

   const [showLanguageMenu, setshowLanguageMenu] = useState(0);

   const [showCurrencyMenu, setshowCurrencyMenu] = useState(0);

   const [showPlanMenu, setshowPlanMenu] = useState(0);

   const [showCategoryMenu, setshowCategoryMenu] = useState(0);
   const [showSubcategoryMenu, setshowSubcategoryMenu] = useState(0);

   const [showServiceMenu, setshowServiceMenu] = useState(0);


   const [showVendorMenu, setshowVendorMenu] = useState(0);

   const [showBookingsMenu, setshowBookingsMenu] = useState(0);
   const [showReviewMenu, setShowReviewMenu] = useState(0);


   const [showNotificationsMenu, setshowNotificationsMenu] = useState(0);

const [showCmsMenu, setshowCmsMenu] = useState(0);

   const dashboardActive = (activePage === 'Dashboard') ? 'active' : '';

   const settingsActive = (activePage === 'Settings') ? 'active' : '';
   const cmsActive = (activePage === 'Cms') ? 'active' : '';
   const emailtemplatesActive = (activePage === 'EmailTemplates') ? 'active' : '';


   const adminuserActive = (activePage === 'Adminusers') ? 'active' : '';
   const rolesActive = (activePage === 'Roles') ? 'active' : '';

   const customerActive = (activePage === 'Customers') ? 'active' : '';

   const vendorActive = (activePage === 'Vendors') ? 'active' : '';




   const EnquiryActive = (activePage === 'Enquiries') ? 'active' : '';

   const SubscribeActive = (activePage === 'Subscribers') ? 'active' : '';



   const faqActive = (activePage === 'Faqs') ? 'active' : '';
   const faqgroupActive = (activePage === 'Faqgroups') ? 'active' : '';


   const sliderActive = (activePage === 'Sliders') ? 'active' : '';
   const bannerActive = (activePage === 'bannerimages' ? 'active' : '');

   const blogActive = (activePage === 'Blog') ? 'active' : '';


   const languageActive = (activePage === 'Languages') ? 'active' : '';

   const currencyActive = (activePage === 'Currencies') ? 'active' : '';


   const planActive = (activePage === 'Plans') ? 'active' : '';


   const AddActive = (activeChildPage == 'Add') ? 'active' : '';
   const ListActive = (activeChildPage == 'List') ? 'active' : '';
   const BlogcategoryActive = (activeChildPage == 'Blogcategory') ? 'active' : '';




   const categoryActive = (activePage === 'Categories') ? 'active' : '';
   const subcategoryActive = (activePage === 'Subcategories') ? 'active' : '';

   const serviceActive = (activePage === 'Services') ? 'active' : '';



   const AddcActive = (activeChildPage == 'Addcat') ? 'active' : '';
   const ListcActive = (activeChildPage == 'Listcat') ? 'active' : '';


   const AddsubActive = (activeChildPage == 'Addsub') ? 'active' : '';
   const ListsubActive = (activeChildPage == 'Listsub') ? 'active' : '';




   const BookingsActive = (activePage === 'Bookings') ? 'active' : '';
   const ReviewsActive = (activePage === 'Reviews') ? 'active' : '';

   const NotificationsActive = (activePage === 'Notifications') ? 'active' : '';


   const handleMenu = (e, type) => {



      switch (type) {
         case 'adminusers':
            setshowAdminuserMenu(1)
            break;
         case 'roles':
            setshowRoleMenu(1)
            break;

         case 'emailtemplates':
            setShowEmailTemplateMenu(1)
            break;

         case 'users':
            setshowUserMenu(1)
            break;

         case 'faqs':
            setshowFaqMenu(1)
            break;

         case 'faqgroups':
            setshowFaqgroupMenu(1)
            break;

         case 'sliders':
            setshowSliderMenu(1)
            break;

         case 'bannerimages':
            setshowBannerMenu(1)
            break;

         case 'blog':
            setshowBlogMenu(1)
            break;


         case 'languages':
            setshowLanguageMenu(1)
            break;


         case 'currencies':
            setshowCurrencyMenu(1)
            break;

         case 'plans':
            setshowPlanMenu(1)
            break;


         case 'categories':
            setshowCategoryMenu(1)
            break;

         case 'subcategories':
            setshowSubcategoryMenu(1)
            break;

         case 'services':
            setshowServiceMenu(1)
            break;





         case 'vendors':
            setshowVendorMenu(1)
            break;


         case 'bookings':
            setshowBookingsMenu(1)
            break;

         case 'reviews':
            setShowReviewMenu(1)
            break;

         case 'notifications':
            setshowNotificationsMenu(1)
            break;

            case 'cms' :
               setshowCmsMenu(1)
               break;

      }

   }

   const SetMenuActive = async () => {

      if (activePage == 'Customers') {
         setshowUserMenu(1);
      }
      if (activePage == 'Roles') {
         setshowRoleMenu(1);
      }

      if (activePage == 'EmailTemplates') {
         setShowEmailTemplateMenu(1);
      }

      if (activePage == 'Adminusers') {
         setshowAdminuserMenu(1);
      }
      if (activePage == 'Faqs') {
         setshowFaqMenu(1);
      }
      if (activePage == 'Faqgroups') {
         setshowFaqgroupMenu(1);
      }

      if (activePage == 'Sliders') {
         setshowSliderMenu(1)

      }


      if (activePage == 'bannerimages') {
         setshowBannerMenu(1)

      }

      if (activePage == 'Blog') {
         setshowBlogMenu(1)

      }

      if (activePage == 'Languages') {
         setshowLanguageMenu(1)

      }

      if (activePage == 'Currencies') {
         setshowCurrencyMenu(1)

      }

      if (activePage == 'Plans') {
         setshowPlanMenu(1)

      }

      if (activePage == 'Categories') {
         setshowCategoryMenu(1)

      }

      if (activePage == 'Subcategories') {
         setshowSubcategoryMenu(1)

      }

      if (activePage == 'Services') {
         setshowServiceMenu(1)

      }

      if (activePage == 'Vendors') {
         setshowVendorMenu(1)

      }

      if (activePage == 'Bookings') {
         setshowBookingsMenu(1)

      }

      if (activePage == 'Reviews') {
         setShowReviewMenu(1)
      }


      if (activePage == 'Notifications') {
         setshowNotificationsMenu(1)

      }

      if (activePage == 'Cms') {
         setshowCmsMenu(1)
      }

   }



   useEffect(() => {
      SetMenuActive();

   }, []);



   return (

      <div id="sidebar" className='active'>
         <div className="sidebar-wrapper active">
            <div className="sidebar-header">
               <img src={SiteLogo} alt="" srcSet={SiteLogo} />
            </div>
            <div className="sidebar-menu">
               <ul className="menu">
                  <li className='sidebar-title'>Main Menu</li>




                  {(Allowdashboard_view == 1) ?
                     <li className={'sidebar-item ' + dashboardActive} >
                        <Link to={baseUrl + 'dashboard'} className='sidebar-link amigo-link text-black text-black'>
                           <i className="fa fa-home" width="20"></i>
                           <span>Dashboard</span>
                        </Link>
                     </li> : ''}



                  {(Allowadminusers_view == 1) ?

                     <li className={'sidebar-item ' + adminuserActive}>
                        <a onClick={(e) => handleMenu(e, 'adminusers')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-user link-icons-sidebar" width="20"></i>
                           <span>Admin Users</span>
                        </a>
                        <ul className={showAdminuserMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'adminusers'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Admin Users</span> </Link>
                           </li>
                           {(Allowadminusers_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'adminusers/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Admin User</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}



                  {(Allowsettings_view == 1) ?

                     <li className={'sidebar-item ' + settingsActive}>
                        <Link to={baseUrl + 'settings'} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-cog" width="20"></i>
                           <span>Settings</span>
                        </Link>
                     </li> : ''}



                  {(Allownotifications_view == 1) ?

                     <li className={'sidebar-item ' + NotificationsActive}>
                        <a onClick={(e) => handleMenu(e, 'notifications')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-bell  link-icons-sidebar" width="20"></i>
                           <span>Notification</span>
                        </a>
                        <ul className={showNotificationsMenu == 1 ? '' : 'display_none'} >

                           <li >
                              <Link to={baseUrl + 'notifications'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Notification</span> </Link>
                           </li>
                           {(Allownotifications_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'notifications/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Notification</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}



                  {(Allowrolesview == 1) ?
                     <li className={'sidebar-item ' + rolesActive}>


                        <a onClick={(e) => handleMenu(e, 'roles')} data-head="roles" className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-user-secret" width="20"></i>
                           <span>Roles</span>
                        </a>

                        <ul className={showRoleMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'roles'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Roles</span> </Link>
                           </li>
                           <li >
                              <Link to={baseUrl + 'roles/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>Add Role</span> </Link>
                           </li>
                        </ul>


                     </li> : ''}




                  {/* {(Allowcms_view == 1) ?
                     <li className={'sidebar-item ' + cmsActive}>
                        <Link to={baseUrl + 'cms'} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-sticky-note" width="20"></i>
                           <span>CMS</span>
                        </Link>
                     </li> : ''} */}


       
{(Allowcms_view == 1) ?

<li className={'sidebar-item ' + cmsActive}>
   <a onClick={(e) => handleMenu(e, 'cms')} className='sidebar-link amigo-link text-black head-link'>
      <i className="fa fa-user link-icons-sidebar" width="20"></i>
      <span>Cms</span>
   </a>
   <ul className={showCmsMenu == 1 ? '' : 'display_none'} >
      <li >
         <Link to={baseUrl + 'cms'} className={ListActive + ' sidebar-link amigo-link text-black'}>
            <i className="fa fa-circle-o"></i><span>List Cms</span> </Link>
      </li>
      {(Allowcms_add == 1) ?

         <li >
            <Link to={baseUrl + 'cms/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
               <i className="fa fa-circle-o"></i><span>Add Cms</span> </Link>
         </li> : ''}

   </ul>
</li> : ''}


                  {/* 
{(Allowemailtemplates_view == 1 ) ?


<li className={'sidebar-item '+emailtemplatesActive}>
<Link to={baseUrl+'emailtemplates'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-envelope" width="20"></i> 
<span>Email Templates</span>
</Link>
</li>: '' } */}


                  {/*  */}

                  {(Allowemailtemplates_view == 1) ? (
                     <li className={'sidebar-item ' + emailtemplatesActive}>
                        <a onClick={(e) => handleMenu(e, 'emailtemplates')} data-head="emailtemplates" className="sidebar-link amigo-link text-black head-link">
                           <i className="fa fa-envelope" width="20"></i>
                           <span>Email Templates</span>
                        </a>
                        <ul className={showEmailTemplateMenu == 1 ? '' : 'display_none'}>
                           <li>
                              <Link to={baseUrl + 'emailtemplates'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Templates</span>
                              </Link>
                           </li>
                           <li>
                              <Link to={baseUrl + 'emailtemplates/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>Add Template</span>
                              </Link>
                           </li>
                        </ul>
                     </li>
                  ) : ''}







                  {(Allowenquiries_view == 1) ?


                     <li className={'sidebar-item ' + EnquiryActive}>
                        <Link to={baseUrl + 'enquiries'} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-comment" width="20"></i>
                           <span>Enquiries</span>
                        </Link>
                     </li> : ''}



                  {(Allowsubscribers_view == 1) ?


                     <li className={'sidebar-item ' + SubscribeActive}>
                        <Link to={baseUrl + 'subscribers'} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-thumbs-up" width="20"></i>
                           <span>Subscribers</span>
                        </Link>
                     </li> : ''}





                  {(Allowcustomers_view == 1) ?

                     <li className={'sidebar-item ' + customerActive}>
                        <a onClick={(e) => handleMenu(e, 'users')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-user link-icons-sidebar" width="20"></i>
                           <span>Customers</span>
                        </a>
                        <ul className={showUserMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'users'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Customers</span> </Link>
                           </li>
                           {(Allowcustomers_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'users/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Customer</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}




                  {(Allowvendors_view == 1) ?

                     <li className={'sidebar-item ' + vendorActive}>
                        <a onClick={(e) => handleMenu(e, 'vendors')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-user link-icons-sidebar" width="20"></i>
                           <span>Vendors</span>
                        </a>
                        <ul className={showVendorMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'vendors'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Vendors</span> </Link>
                           </li>
                           {(Allowvendors_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'vendors/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Vendor</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}



                  {(Allowfaqs_view == 1 || Allowfaqgroups_view == 1) ?

                     <li className={'sidebar-item ' + faqActive}>
                        <a onClick={(e) => handleMenu(e, 'faqs')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-question link-icons-sidebar" width="20"></i>
                           <span>Faq</span>
                        </a>


                        <ul className={showFaqMenu == 1 ? '' : 'display_none'} >

                           <li >
                              <Link to={baseUrl + 'faqgroup'} className={ListcActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Faq group</span> </Link>
                           </li>
                           {(Allowfaqgroups_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'faqgroup/add'} className={AddcActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Faq group</span> </Link>
                              </li> : ''}


                           <li >
                              <Link to={baseUrl + 'faq'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Faq</span> </Link>
                           </li>
                           {(Allowfaqs_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'faq/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Faq</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}



                  { /*
{(Allowsliders_view == 1 ) ?

 <li className={'sidebar-item '+sliderActive}>
   <a onClick={(e) => handleMenu(e,'sliders')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-image  link-icons-sidebar" width="20"></i> 
   <span>Slider</span>
   </a>
   <ul className={showSliderMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'sliders'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Slider</span> </Link>
   </li>
   {(Allowsliders_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'sliders/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Slider</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }

*/ }

                  {(Allowsliders_view == 1) ?

                     <li className={'sidebar-item ' + sliderActive}>
                        <a onClick={(e) => handleMenu(e, 'sliders')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-image  link-icons-sidebar" width="20"></i>
                           <span>Slider</span>
                        </a>
                        <ul className={showSliderMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'sliders'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Slider</span> </Link>
                           </li>
                           {(Allowsliders_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'sliders/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Slider</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}


                  {(Allowbannerimage_view == 1) ?

                     <li className={'sidebar-item ' + bannerActive}>
                        <a onClick={(e) => handleMenu(e, 'bannerimages')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-image  link-icons-sidebar" width="20"></i>
                           <span>Banner</span>
                        </a>
                        <ul className={showbannerMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'bannervideo'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Banner</span> </Link>
                           </li>
                           {(Allowbannerimage_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'bannervideo/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Banner</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}







                  {(Allowservices_view == 1 || Allowsubcategories_view == 1 || Allowcategories_view == 1) ?

                     <li className={'sidebar-item ' + serviceActive}>
                        <a onClick={(e) => handleMenu(e, 'services')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-wrench  link-icons-sidebar" width="20"></i>
                           <span>Service</span>
                        </a>



                        <ul className={showServiceMenu == 1 ? '' : 'display_none'} >

                           {(Allowcategories_view == 1) ?

                              <li >
                                 <Link to={baseUrl + 'categories'} className={ListcActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>List Category</span> </Link>
                              </li>
                              : ''}


                           {(Allowcategories_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'categories/add'} className={AddcActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Category</span> </Link>
                              </li> : ''}



                           {(Allowsubcategories_view == 1) ?

                              <li >
                                 <Link to={baseUrl + 'subcategories'} className={ListsubActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>List Subcategory</span> </Link>
                              </li> : ''}


                           {(Allowsubcategories_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'subcategories/add'} className={AddsubActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Subcategory</span> </Link>
                              </li> : ''}



                           {(Allowservices_view == 1) ?

                              <li >
                                 <Link to={baseUrl + 'services'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>List Service</span> </Link>
                              </li> : ''}


                           {(Allowservices_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'services/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Service</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}


                  {(Allowbookings_view == 1) ?
                     <li className={'sidebar-item ' + BookingsActive}>
                        <Link to={baseUrl + 'bookings'} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-book" width="20"></i>
                           <span>Bookings</span>
                        </Link>
                     </li> : ''}


                  {/*  */}

                  {/* {(Allowbookings_view == 1 ) ?
<li className={'sidebar-item '+ ReviewsActive}>
<Link to={baseUrl+'reviews'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-star" width="20"></i> 
<span>Reviews</span>
</Link>
</li>: '' } */}

                  {/*  */}


                  {(Allowblog_view == 1 || Allowblogcategory_view) ?

                     <li className={'sidebar-item ' + blogActive}>
                        <a onClick={(e) => handleMenu(e, 'blog')} className='sidebar-link amigo-link text-black head-link'>
                           <i className="fa fa-newspaper-o  link-icons-sidebar" width="20"></i>
                           <span>Blog</span>
                        </a>
                        <ul className={showBlogMenu == 1 ? '' : 'display_none'} >
                           <li >
                              <Link to={baseUrl + 'blogcategory'} className={BlogcategoryActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Blog Category</span> </Link>
                           </li>

                           <li >
                              <Link to={baseUrl + 'blog'} className={ListActive + ' sidebar-link amigo-link text-black'}>
                                 <i className="fa fa-circle-o"></i><span>List Blog</span> </Link>
                           </li>
                           {(Allowblog_add == 1) ?

                              <li >
                                 <Link to={baseUrl + 'blog/add'} className={AddActive + ' sidebar-link amigo-link text-black'}>
                                    <i className="fa fa-circle-o"></i><span>Add Blog</span> </Link>
                              </li> : ''}

                        </ul>
                     </li> : ''}


                  { /*

{(Allowlanguages_view == 1 ) ?

 <li className={'sidebar-item '+languageActive}>
   <a onClick={(e) => handleMenu(e,'languages')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-language  link-icons-sidebar" width="20"></i> 
   <span>Language</span>
   </a>
   <ul className={showLanguageMenu == 1 ? '' :'display_none'} > 
 
   <li >
      <Link to={baseUrl+'languages'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Language</span> </Link>
   </li>
   {(Allowlanguages_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'languages/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Language</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }





{(Allowcurrencies_view == 1 ) ?

 <li className={'sidebar-item '+currencyActive}>
   <a onClick={(e) => handleMenu(e,'currencies')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-money  link-icons-sidebar" width="20"></i> 
   <span>Currency</span>
   </a>
   <ul className={showCurrencyMenu == 1 ? '' :'display_none'} > 
 
   <li >
      <Link to={baseUrl+'currencies'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Currency</span> </Link>
   </li>
   {(Allowcurrencies_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'currencies/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Currency</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }

{ (Allowplans_view == 1 ) ?

 <li className={'sidebar-item '+planActive}>
   <a onClick={(e) => handleMenu(e,'plans')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-money  link-icons-sidebar" width="20"></i> 
   <span>Plan</span>
   </a>
   <ul className={showPlanMenu == 1 ? '' :'display_none'} > 
 
   <li >
      <Link to={baseUrl+'plans'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Plan</span> </Link>
   </li>
   {(Allowplans_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'plans/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Plan</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }
 */ }

               </ul>






            </div>
            <button className="sidebar-toggler btn x amigo-button"><i className="fa fa-eyex"></i></button>
         </div>
      </div>



   );
};
export default Sidebar;