import React, { useEffect, useState } from "react";
import {
   Link,
   useNavigate
} from "react-router-dom";
import { baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";
import {
   Allowvendors_view,
   Allowvendors_add,
   Allowvendors_edit,
   Allowvendors_delete
} from './../../Permissions.js';


const Userlist = () => {

   const [vendorsList, setvendorsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage] = useState(10);
   const [nPages, setnPages] = useState(1);
   const [pageNumbers, setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

   const [searchTerm, setSearchTerm] = useState(""); 

   const goToNextPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage + 1);
         GetVendors();
      }
   };
   
   const goToPrevPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage - 1);
         GetVendors();
      }
   };

   const goToPage = (value) => {
      setCurrentPage(value);
      GetVendors(value);
   };

   const GetVendors = async (value) => {
      value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata = { 'currentpage': value, 'perpage': recordsPerPage };

      const response = await fetch(Url + "getvendors", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ postdata }),
      }).then((response) => response.json())
      
         .then((data) => {
            if (data.success) {
               setvendorsList(data.data);
               if (data.count > 1) {
                  setpageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1));
               }
            }
         });
   };

   const DeleteConfirm = async (id) => {
      if (window.confirm("Are you sure?")) {
         var postdata = { 'id': id };
         const response = await fetch(Url + "deletevendor", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),
         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  DeleteNotify();
               } else {
                  DeleteNotify(data.message);
               }
               GetVendors();
            });
      }
   };

   const filteredVendors = vendorsList.filter(vendor => 
      vendor.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.username.toLowerCase().includes(searchTerm.toLowerCase())
   );

   useEffect(() => {
      GetVendors();
      checkpermissionredirect(Allowvendors_view);
   }, []);

   return (
      <div id="app">
         <Header title="Vendors List" />

         <Sidebar activePage="Vendors" activeChildPage="List" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">

               <Pagetitle activePage="Vendors" />

               {ModalPopupDelete()}

               <section className="section">
                  <div className="card">
                     <div className="card-header">
                        <span>Vendors</span>

                        {(Allowvendors_add == 1) ?
                           <div className="pull-right"><a href={baseUrl + 'vendors/add'} className="btn btn-success me-1 mb-1">Add Vendor</a></div> : ''}

                     </div>
                     <div className="card-body">
                        <input type="text"  placeholder="Search..."  className="form-control mb-3"  value={searchTerm}  onChange={(e) => setSearchTerm(e.target.value)}   />

                        <table className='table table-striped' id="table1">
                           <thead>
                              <tr>
                                 <th>Name</th>
                                 <th>Email</th>
                                 <th>Status</th>
                                 <th>Reviews</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           {filteredVendors.length > 0 ?
                              <tbody>
                                 {
                                    filteredVendors.map((user) => (
                                       (user.username !== 'admin') ?
                                          <tr key={user._id}>
                                             <td>{user.fullname}</td>
                                             <td>{user.email}</td>

                                             <td>
                                                {(user.status == 1) ? 
                                                   <span className="badge bg-success">Active</span> : 
                                                   <span className="badge bg-danger">InActive</span>
                                                }
                                             </td>
                                             <td>
                                                <Link to={baseUrl + 'reviews/' + user._id} className="btn btn-info btn-sm">Reviews</Link>
                                             </td>
                                             <td className="table-action-sections">
                                                {(Allowvendors_edit == 1) ?
                                                   <Link to={baseUrl + 'vendors/edit/' + user._id} className="fa-edit-btn">
                                                      <i className="fa fa-pencil"></i> Edit
                                                   </Link> : ''}

                                                {(Allowvendors_delete == 1) ?
                                                   <a onClick={() => DeleteConfirm(user._id)} className="fa-del-btn">
                                                      <i className="fa fa-trash"></i> Delete
                                                   </a> : ''}
                                             </td>
                                          </tr> : ''
                                    ))
                                 }
                              </tbody> : 
                              <tbody><tr> <td colSpan="4" align="center">No Records Found</td> </tr></tbody>
                           }
                        </table>
                     </div>
                  </div>

                  {pageNumbers.length > 1 ?
                     <nav>
                        <ul className='pagination pagination-primary justify-content-center'>
                           <li className="page-item prev-paging-single">
                              <a className="page-link"
                                 onClick={goToPrevPage}
                                 href='#'>
                                 Previous
                              </a>
                           </li>
                           {pageNumbers.map(pgNumber => (
                              <li key={pgNumber}
                                 className={`page-item ${currentPage == pgNumber ? 'active' : ''}`}>
                                 <a onClick={() => goToPage(pgNumber)} 
                                    className='page-link' href='#'>
                                    {pgNumber}
                                 </a>
                              </li>
                           ))}
                           <li className="page-item paging-next-single">
                              <a className="page-link" onClick={goToNextPage} href='#'> Next </a>
                           </li>
                        </ul>
                     </nav> : ''
                  }
               </section>

               <Footer />
            </div>
         </div>
      </div>
   );
};
export default Userlist;
