import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Url } from './../Api.js';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";
import Footer from "../includes/Footer";

const Myservices = () => {
    const [serviceList, setServiceList] = useState([]);
    const [recordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [nPages, setNPages] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([1]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchServiceList();
    }, [currentPage]);

    const fetchServiceList = async () => {
        try {
            const postdata = { currentPage, perpage: recordsPerPage };

            const response = await fetch(Url + "vendor-portal-services", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ postdata }),
            }).then((response) => response.json());

            if (response) {
                setServiceList(response.data);
                const totalRecords = response.count;
                const pages = Math.ceil(totalRecords / recordsPerPage);
                setNPages(pages);
                setPageNumbers(Array.from({ length: pages }, (_, index) => index + 1));
            } else {
                console.error("Failed to fetch data:", response.message);
            }
        } catch (error) {
            console.error("Error fetching service list:", error);
        }
    };

    const goToNextPage = () => {
        if (currentPage < nPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToPage = (value) => {
        setCurrentPage(value);
    };

    // const deleteService = async (serviceId) => {
    //     if (!serviceId) {
    //         console.error("Invalid serviceId, can't delete the service.");
    //         alert("Invalid service ID, can't delete the service.");
    //         return;
    //     }
    
    //     console.log("Sending delete request for serviceId:", serviceId);  
    
    //     try {
    //         const response = await fetch(Url + "vendor-delete-services", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 serviceId: serviceId
    //             }),
    //         });
    
    //         const result = await response.json();
            
    //         console.log("Delete response:", result);  
    
    //         if (result) {
    //             fetchServiceList();
    
    //             setServiceList((prevList) =>
    //                 prevList.filter((service) => service.serviceId?._id !== serviceId)
    //             );
    
    //             alert("Service deleted successfully");
    //         } else {
    //             console.error("Failed to delete service:", result.message);
    //             alert("Failed to delete service");
    //         }
    //     } catch (error) {
    //         console.error("Error deleting service:", error);
    //         alert("An error occurred while deleting the service");
    //     }
    // };


    return (
        <div id="app">
            <Header title="My Services" />
            <Sidebar activePage="Myservices" activeChildPage="List" />
            <div id="main">
                <Navbar />
                <div className="main-content container-fluid">
                    <Pagetitle activePage="Myservices" />
                    <section className="section">
                        <div className="card">
                            <div className="card-header">
                                <span>My Services</span>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped" id="table1">
                                    <thead>
                                        <tr>
                                            <th>Service Name</th>
                                            {/* <th>Price</th> */}
                                            {/* <th>Image</th> */}
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceList.length > 0 ? (
                                            serviceList.map((service) => (
                                                <tr key={service.id}>
                                                    <td>{service.serviceId?.name || 'No Name Available'}</td> 
                                                    {/* <td>{service.serviceId?.price || 'No Price Available'}</td> */}
                                                    {/* <td>
                                                        {service.serviceId?.image ? (
                                                            <img
                                                                src={service.serviceId.image}
                                                                className="img-responsive w-100px"
                                                                alt={service.serviceId?.name || 'Service Image'}
                                                            />
                                                        ) : (
                                                            <span>No Image Available</span>
                                                        )}
                                                    </td> */}
                                                    {/* <td className="table-action-sections">
                                                        <button className="btn btn-danger" onClick={() => deleteService(service.serviceId?._id)}>
                                                            Delete
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" align="center">
                                                    No Records Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {nPages > 1 && (
                            <nav>
                                <ul className="pagination pagination-primary justify-content-center">
                                    <li className="page-item prev-paging-single">
                                        <a className="page-link" onClick={goToPrevPage} href="#">
                                            Previous
                                        </a>
                                    </li>
                                    {pageNumbers.map((pgNumber) => (
                                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? "active" : ""}`}>
                                            <a onClick={() => goToPage(pgNumber)} className="page-link" href="#">
                                                {pgNumber}
                                            </a>
                                        </li>
                                    ))}
                                    <li className="page-item paging-next-single">
                                        <a className="page-link" onClick={goToNextPage} href="#">
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </section>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Myservices;
