import React, { useState } from "react";
import {    useLocation,    useNavigate} from "react-router-dom";
  
 
import {baseUrl,Url,Socketiourl,userId , userEmail,userName} from "./Api";
// import cms from "../../server/models/cms";




 

const CheckPermissions = async() => {   

         

// if(userName == 'admin' && userEmail =='admin@umycompany.com' || (typeof userId == 'undefined' ||  userId ==  null || userId == '')){ 
// return {  
//       dashboard_view: '1',
//     settings_view: '1',
//     settings_edit: '1',
//     customers_view: '1',
//     customers_add: '1',
//     customers_edit: '1',
//     customers_delete: '1',

//         vendors_view: '1',
//     vendors_add: '1',
//     vendors_edit: '1',
//     vendors_delete: '1',


//     cms_view: '1',
//     cms_edit: '1',
//     emailtemplates_view: '1',
//     emailtemplates_edit: '1',
 
//     orders_view: '1',
//     orders_edit: '1',
//     enquiries_view: '1',
//     subscribers_view : '1',
 
//     adminusers_view:'1',
//     adminusers_add:'1',
//     roles_view:1,

//      faqs_view: '1',
//     faqs_add: '1',
//     faqs_edit: '1',
//     faqs_delete: '1',
//          faqgroups_view: '1',
//     faqgroups_add: '1',
//     faqgroups_edit: '1',
//     faqgroups_delete: '1',

//     sliders_view: '1',
//     sliders_add: '1',
//     sliders_edit: '1',
//     sliders_delete: '1',

//      blog_view: '1',
//     blog_add: '1',
//     blog_edit: '1',
//     blog_delete: '1',
//         blogcategory_view: '1',
//     blogcategory_add: '1',
//     blogcategory_edit: '1',
//     blogcategory_delete: '1',

//      languages_view: '1',
//     languages_add: '1',
//     languages_edit: '1',
//     languages_delete: '1',

//     currencies_view: '1',
//     currencies_add: '1',
//     currencies_edit: '1',
//     currencies_delete: '1',



//     plans_view: '1',
//     plans_add: '1',
//     plans_edit: '1',
//     plans_delete: '1',

//       subcategories_view: '1',
//     subcategories_add: '1',
//     subcategories_edit: '1',
//     subcategories_delete: '1',
//       categories_view: '1',
//     categories_add: '1',
//     categories_edit: '1',
//     categories_delete: '1',

//      services_view: '1',
//     services_add: '1',
//     services_edit: '1',
//     services_delete: '1',


//     bookings_view:'1',


//      notifications_view:'1',
//      notifications_delete:'1',

//      notifications_add:'1'


//      };
// }


if (typeof userId == 'undefined' || userId == null || userId == '' || userName && userEmail) { 
  return {  
      
      dashboard_view: '1',
      settings_view: '1',
      settings_edit: '1',
      customers_view: '1',
      customers_add: '1',
      customers_edit: '1',
      customers_delete: '1',

      vendors_view: '1',
      vendors_add: '1',
      vendors_edit: '1',
      vendors_delete: '1',

      cms_view: '1',
      cms_edit: '1',
      cms_add : '1',
      cms_delete : '1',

      emailtemplates_view: '1',
      emailtemplates_edit: '1',

      orders_view: '1',
      orders_edit: '1',
      enquiries_view: '1',
      subscribers_view : '1',

      adminusers_view:'1',
      adminusers_add:'1',
      adminusers_edit: '1',
      adminusers_delete:'1',
      roles_view:'1',

      faqs_view: '1',
      faqs_add: '1',
      faqs_edit: '1',
      faqs_delete: '1',
      faqgroups_view: '1',
      faqgroups_add: '1',
      faqgroups_edit: '1',
      faqgroups_delete: '1',

      sliders_view: '1',
      sliders_add: '1',
      sliders_edit: '1',
      sliders_delete: '1',


      bannerimage_view: '1',
      bannerimage_add: '1',
      bannerimage_edit: '1',
      bannerimage_delete: '1',

      blog_view: '1',
      blog_add: '1',
      blog_edit: '1',
      blog_delete: '1',
      blogcategory_view: '1',
      blogcategory_add: '1',
      blogcategory_edit: '1',
      blogcategory_delete: '1',

      languages_view: '1',
      languages_add: '1',
      languages_edit: '1',
      languages_delete: '1',

      currencies_view: '1',
      currencies_add: '1',
      currencies_edit: '1',
      currencies_delete: '1',

      plans_view: '1',
      plans_add: '1',
      plans_edit: '1',
      plans_delete: '1',

      subcategories_view: '1',
      subcategories_add: '1',
      subcategories_edit: '1',
      subcategories_delete: '1',
      categories_view: '1',
      categories_add: '1',
      categories_edit: '1',
      categories_delete: '1',

      services_view: '1',
      services_add: '1',
      services_edit: '1',
      services_delete: '1',

      bookings_view:'1',
      reviews_view: '1',

      notifications_view:'1',
      notifications_delete:'1',
      notifications_add:'1',

  
  };
} else {
  
  return {
      dashboard_view: '0',  
      settings_view: '0',  
      customers_view: '0',
      vendors_view: '0',
      cms_view: '0',
      orders_view: '0',
      faqs_view: '0',
      blog_view: '0',
      languages_view: '0',
      plans_view: '0',
      subcategories_view: '0',
      services_view: '0',
      bookings_view: '0',
      reviews_view: '0',
      notifications_view: '0'
  };
}



var postdata={  userid:userId   }
var result =  await  fetch(Url + "check-permissions", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
    
    if(data.success){

         return data.data

     }
                 
    })

    return result
//
}

const  Permissionsv=await CheckPermissions()

 

 const Allowdashboard_view =  Permissionsv.dashboard_view
  const Allowsettings_view =  Permissionsv.settings_view

  const Allowsettings_edit =  Permissionsv.settings_edit
  const Allowcustomers_view =  Permissionsv.customers_view
  const Allowcustomers_add =  Permissionsv.customers_add
  const Allowcustomers_edit =  Permissionsv.customers_edit
  const Allowcustomers_delete =  Permissionsv.customers_delete
  
  const Allowcms_view =  Permissionsv.cms_view
  const Allowcms_edit =  Permissionsv.cms_edit
const Allowcms_add = Permissionsv.cms_add
const Allowcms_delete = Permissionsv.cms_delete



  const Allowemailtemplates_view =  Permissionsv.emailtemplates_view
  const Allowemailtemplates_edit =  Permissionsv.emailtemplates_edit
   const Alloworders_view =  Permissionsv.orders_view
  const Alloworders_edit =  Permissionsv.orders_edit
  const Allowenquiries_view =  Permissionsv.enquiries_view


  const Allowsubscribers_view = Permissionsv.subscribers_view
  
    const Allowclients_view =  Permissionsv.clients_view

  const Allowrolesview =  Permissionsv.roles_view

  
  const Allowadminusers_view=  Permissionsv.adminusers_view
  const Allowadminusers_add=  Permissionsv.adminusers_add
  const Allowadminusers_edit=  Permissionsv.adminusers_edit
  const Allowadminusers_delete=  Permissionsv.adminusers_delete

  const Allowfaqs_view=  Permissionsv.faqs_view
  const Allowfaqs_add=  Permissionsv.faqs_add
  const Allowfaqs_edit=  Permissionsv.faqs_edit
  const Allowfaqs_delete=  Permissionsv.faqs_delete

    const Allowfaqgroups_view=  Permissionsv.faqgroups_view
  const Allowfaqgroups_add=  Permissionsv.faqgroups_add
  const Allowfaqgroups_edit=  Permissionsv.faqgroups_edit
  const Allowfaqgroups_delete=  Permissionsv.faqgroups_delete




  const Allowsliders_view=  Permissionsv.sliders_view
  const Allowsliders_add=  Permissionsv.sliders_add
  const Allowsliders_edit=  Permissionsv.sliders_edit
  const Allowsliders_delete=  Permissionsv.sliders_delete


  const Allowbannerimage_view = Permissionsv.bannerimage_view
  const Allowbannerimage_add = Permissionsv.bannerimage_add
  const Allowbannerimage_edit = Permissionsv.bannerimage_edit
  const Allowbannerimage_delete = Permissionsv.bannerimage_delete

  const Allowblog_view=  Permissionsv.blog_view
  const Allowblog_add=  Permissionsv.blog_add
  const Allowblog_edit=  Permissionsv.blog_edit
  const Allowblog_delete=  Permissionsv.blog_delete

  
  const Allowblogcategory_view=  Permissionsv.blogcategory_view
  const Allowblogcategory_add=  Permissionsv.blogcategory_add
  const Allowblogcategory_edit=  Permissionsv.blogcategory_edit
  const Allowblogcategory_delete=  Permissionsv.blogcategory_delete


  const Allowlanguages_view=  Permissionsv.languages_view
  const Allowlanguages_add=  Permissionsv.languages_add
  const Allowlanguages_edit=  Permissionsv.languages_edit
  const Allowlanguages_delete=  Permissionsv.languages_delete


  const Allowcurrencies_view=  Permissionsv.currencies_view
  const Allowcurrencies_add=  Permissionsv.currencies_add
  const Allowcurrencies_edit=  Permissionsv.currencies_edit
  const Allowcurrencies_delete=  Permissionsv.currencies_delete


  const Allowplans_view=  Permissionsv.plans_view
  const Allowplans_add=  Permissionsv.plans_add
  const Allowplans_edit=  Permissionsv.plans_edit
  const Allowplans_delete=  Permissionsv.plans_delete


   const Allowcategories_view=  Permissionsv.categories_view
  const Allowcategories_add=  Permissionsv.categories_add
  const Allowcategories_edit=  Permissionsv.categories_edit
  const Allowcategories_delete=  Permissionsv.categories_delete

 const Allowsubcategories_view=  Permissionsv.subcategories_view
  const Allowsubcategories_add=  Permissionsv.subcategories_add
  const Allowsubcategories_edit=  Permissionsv.subcategories_edit
  const Allowsubcategories_delete=  Permissionsv.subcategories_delete

       const Allowservices_view=  Permissionsv.services_view
  const Allowservices_add=  Permissionsv.services_add
  const Allowservices_edit=  Permissionsv.services_edit
  const Allowservices_delete=  Permissionsv.services_delete


   const Allowvendors_view =  Permissionsv.vendors_view
  const Allowvendors_add =  Permissionsv.vendors_add
  const Allowvendors_edit =  Permissionsv.vendors_edit
  const Allowvendors_delete =  Permissionsv.vendors_delete


   const Allowbookings_view =  Permissionsv.bookings_view

   const Allowreviews_view = Permissionsv.reviews_view


   const Allownotifications_view =  Permissionsv.notifications_view
   const Allownotifications_add =  Permissionsv.notifications_add
   const Allownotifications_delete =  Permissionsv.notifications_delete





 export{
    Allowrolesview,
      Allowdashboard_view ,
   Allowsettings_view ,
   Allowsettings_edit ,
   Allowcustomers_view ,
   Allowcustomers_add ,
   Allowcustomers_edit ,
   Allowcustomers_delete ,


      Allowvendors_view ,
   Allowvendors_add ,
   Allowvendors_edit ,
   Allowvendors_delete ,

   
   Allowcms_view ,
   Allowcms_edit ,
Allowcms_add,
Allowcms_delete,

   Allowemailtemplates_view ,
   Allowemailtemplates_edit ,
 
   Alloworders_view ,
   Alloworders_edit ,
   Allowenquiries_view ,
   Allowsubscribers_view,
 
   Allowadminusers_view,
      Allowadminusers_add,
      Allowadminusers_edit,
      Allowadminusers_delete,


      Allowfaqs_view,
Allowfaqs_add,  
 Allowfaqs_edit,
 Allowfaqs_delete,
      Allowfaqgroups_view,
Allowfaqgroups_add,  
 Allowfaqgroups_edit,
 Allowfaqgroups_delete,


      Allowsliders_view,
Allowsliders_add,  
 Allowsliders_edit,
 Allowsliders_delete,

 Allowbannerimage_view,
 Allowbannerimage_add,
 Allowbannerimage_edit,
 Allowbannerimage_delete,

      Allowblog_view,
Allowblog_add,  
 Allowblog_edit,
 Allowblog_delete,


 Allowblogcategory_add,  
 Allowblogcategory_edit,
 Allowblogcategory_delete,


 Allowblogcategory_view,


       Allowlanguages_view,
Allowlanguages_add,  
 Allowlanguages_edit,
 Allowlanguages_delete,


       Allowcurrencies_view,
Allowcurrencies_add,  
 Allowcurrencies_edit,
 Allowcurrencies_delete,

  Allowplans_view,
Allowplans_add,  
 Allowplans_edit,
 Allowplans_delete,


  Allowsubcategories_view,
Allowsubcategories_add,  
 Allowsubcategories_edit,
 Allowsubcategories_delete,


 
  Allowcategories_view,
Allowcategories_add,  
 Allowcategories_edit,
 Allowcategories_delete,

Allowreviews_view,
 
 
  Allowservices_view,
Allowservices_add,  
 Allowservices_edit,
 Allowservices_delete,

 Allowbookings_view,
 Allownotifications_view,
Allownotifications_add,
Allownotifications_delete
 }
  
//export {    dahboardactive};