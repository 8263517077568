import React, {
    useEffect,
    useState
} from "react";
import {
    Url,
    CommonNotify,
    UrlSplitter,
    checkEmptyUndefined, redirectPage,
    checkpermissionredirect
} from './../../Api.js';

import {
    Allowcms_view,
    Allowcms_edit,
} from './../../Permissions.js';

// import {
//     CKEditor
// } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Editor
} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";
const General = () => {
    const [notification, setnotification] = useState("");
    const [notificationtype, setnotificationtype] = useState("");
    const [id] = useState(UrlSplitter(4));
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [submitLoading, setsubmitLoading] = useState(false);
    const [status, setStatus] = useState(1);
    const [language, setLanguage] = useState("");
    const [url, setUrl] = useState("");  

    /*
      const timeZones = Intl.supportedValuesOf('timeZone')
     */
    const Getcms = async (e) => {
        try {
            var postdata = {
                _id: id
            };
            const response = await fetch(Url + "getcms", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var cmsdata = data.data;
                        setName(cmsdata.name);
                        setStatus(cmsdata.status);
                        setContent(cmsdata.content);
                        setLanguage(cmsdata.language);
                        setUrl(cmsdata.url);  
                    } else {
                        //   
                    }
                })
        } catch (error) {
            //
        }
    }
    const Updatecms = async (e) => {
        e.preventDefault();

        setsubmitLoading(true)
        if (checkEmptyUndefined(name) === false) {
            setnotificationtype('error')
            setnotification('* Fields Required')
            setsubmitLoading(false)
            return false
        }

        try {
            var statusupdate = (typeof status == 'undefined' || status == '' || status == '1') ? '1' : 0;

            var postdata = {
                _id: id,
                name: name,
                content: content,
                language: language,
                status: statusupdate,
                url: url 
            };

            const response = await fetch(Url + "updatecms", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setnotificationtype('success')
                        setnotification(data.message)
                        redirectPage('cms')
                    } else {
                        setnotificationtype('error')
                        setnotification(data.message)
                    }
                })
            setsubmitLoading(false)
        } catch (error) { alert(error.message) }
    };
    const handleUserInput = (e) => {
        setnotification("");
        var name = e.target.name; 
        var value = e.target.value;
        console.log(value)
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'language':
                setLanguage(value);
                break;
            case 'content':
                setContent(value);
                break;
            case 'status':
                setStatus(value);
                break;
            case 'url':  
                setUrl(value);
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        Getcms(id);

        checkpermissionredirect(Allowcms_edit)
    }, []);

    return (

        <div id="app">
            <Header title={typeof id !== 'undefined' ? "Edit CMS" : "Add CMS"} />
            <Sidebar activePage="CMS" />
            <div id="main">
                <Navbar />
                <div className="main-content container-fluid">
                    <Pagetitle activePage={typeof id !== 'undefined' ? "Edit CMS" : "Add CMS"} />
                    <div className="row match-height">
                        <div className="col-md-9 col-12 mx-auto">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">{typeof id !== 'undefined' ? "Edit CMS" : "Add CMS"}</h4>
                                </div>
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form form-horizontal">
                                            <div className="form-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Name</label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input className="form-control" value={name} type="text" name="name" placeholder="Name"
                                                            onChange={(e) => handleUserInput(e)}
                                                            required />
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>Language</label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input className="form-control" value={language}  name="language"  placeholder="language"
                                                            onChange={(e) => handleUserInput(e)}
                                                            required />
                                                    </div>


                                                    <div className="col-md-4">
                                                        <label>Content</label>
                                                    </div>
                                                    <div className="col-md-8 form-group">

                                                        <textarea name="content" onChange={(e) => handleUserInput(e)} rows="15" cols="100" className="form-control" value={content} >{content}</textarea>
                                                    </div>
                                                    
                                                    <div className="col-md-4">
                                                        <label>Status <span className="field-required">*</span></label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <select className="form-control" name="status" onChange={(e) => handleUserInput(e)}>
                                                            <option value='1' selected={1 == status ? 'selected' : ''}  >Active</option>
                                                            <option value='0' selected={0 == status ? 'selected' : ''}  >InActive</option>

                                                        </select>

                                                    </div>

                                           
                                                    <div className="col-md-4">
                                                        <label>URL</label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input className="form-control" value={url} name="url" placeholder="URL"
                                                            onChange={(e) => handleUserInput(e)} />
                                                    </div>

                                                    {(submitLoading === false) ? (
                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatecms}>Submit</button>
                                                        </div>
                                                    ) : ''}
                                                    {
                                                        (notification !== '') ? (
                                                            CommonNotify(notification, notificationtype)
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};
export default General;

