import React, { useEffect, useState } from "react";
import {
   Link,
   useNavigate
} from "react-router-dom";
import { Getblogimage, baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../Api.js';


import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";

import Footer from "../includes/Footer";

//import Pagination from "../includes/Pagination";


const Myearnings = () => {


   const [blogList, setblogList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage] = useState(10);

   //const [notification, setnotification] = useState("");
   //const [notificationtype, setnotificationtype] = useState("");
   const [nPages, setnPages] = useState(1);
   const [pageNumbers, setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

   //const nPages = Math.ceil(blogList.length / recordsPerPage)

   const goToNextPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage + 1)
         GetBlog()
      }
   }
   const goToPrevPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage - 1)
         GetBlog()

      }
   }


   const goToPage = (value) => {
      setCurrentPage(value)

      GetBlog(value)

   }

   const GetBlog = async (value) => {

      // let formData = new FormData()
      //  formData.append('currentpage', currentPage)
      //   formData.append('perpage', recordsPerPage)

      value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata = { admin: 1, 'currentpage': value, 'perpage': recordsPerPage };

      const response = await fetch(Url + "getblog", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ postdata }),

      }).then((response) => response.json())
         .then((data) => {
            if (data.success) {

               var blogdata = data.data;

               Object.keys(blogdata).forEach(function (key) {
                  (async function () {



                     blogdata[key].image = await Getblogimage(blogdata[key].image)




                     setblogList(blogdata);


                  })()
               })

               if (data.count > 1) {
                  //setnPages(data.count / recordsPerPage);

                  setpageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1))

               }

            } else {

            }

         })
   };


   const DeleteConfirm = async (id) => {

      if (window.confirm("Are you sure?")) {
         var postdata = { 'id': id };
         const response = await fetch(Url + "deleteblog", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),

         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  DeleteNotify()
               } else {
                  DeleteNotify(data.message);
               }

               GetBlog();


            })

      }

   };



   // const indexOfLastRecord = currentPage * recordsPerPage;
   //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
   //  const currentRecords = blogList.slice(indexOfFirstRecord, indexOfLastRecord);
   //  const nPages = Math.ceil(blogList.length / recordsPerPage)



   //pagination part



   useEffect(() => {
      GetBlog();





   }, []);

   //pagination part

   return (
      <div id="app">
         <Header title="My Earnings" />

         <Sidebar activePage="Myearnings" activeChildPage="List" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">

               <Pagetitle activePage="Myearnings " />


               <section className="section">
                  <div className="card">
                     <div className="card-header">
                        <span>My Earnings </span>


                     </div>
                     <div className="card-body">


                        <table className='table table-striped' id="table1">
                           <thead>
                              <tr>
                                 <th>Title</th>

                                 <th>Image</th>

                                 <th>Status</th>
                                 <th>Action</th>
                              </tr>
                           </thead>

                           {blogList.length > 0 ?
                              <tbody>

                                 {blogList.map((blog) => (
                                    <tr>
                                       <td>{blog.title}</td>



                                       <td><img src={blog.image} className="img-responsive w-100px" /></td>



                                       <td>
                                          {(blog.status == 1) ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">InActive</span>}

                                       </td>
                                       <td className="table-action-sections">






                                       </td>
                                    </tr> : ''
                        
                           
                          
                     ))  }

                              </tbody> : <tbody><tr> <td colspan="4" align="center" >No Records Found </td> </tr></tbody>}
                        </table>
                     </div>
                  </div>

                  {pageNumbers.length > 1 ?

                     <nav>
                        <ul className='pagination pagination-primary justify-content-center'>
                           <li className="page-item prev-paging-single">
                              <a className="page-link"
                                 onClick={goToPrevPage}
                                 href='#'>
                                 Previous
                              </a>
                           </li>
                           {pageNumbers.map(pgNumber => (
                              <li key={pgNumber}
                                 className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                                 <a onClick={(e) => goToPage(pgNumber)}
                                    className='page-link'
                                    href='#'>
                                    {pgNumber}
                                 </a>
                              </li>
                           ))}
                           <li className="page-item paging-next-single">
                              <a className="page-link"
                                 onClick={goToNextPage}
                                 href='#'>
                                 Next
                              </a>
                           </li>
                        </ul>
                     </nav>
                     : ''

                  }
               </section>

               <Footer />
            </div>


         </div>

      </div>


   );
};
export default Myearnings;