import React, { useEffect, useState } from "react";

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';



import { Siteinfo, Redirectlogin, frontUrl, SiteFavicon } from '../Frontapi.js';
import MetaTags from 'react-meta-tags';
import '../includes/Header.css';
import 'font-awesome/css/font-awesome.min.css';



// import  ('../../assets/css/frontend/style.css');
import umy_logo from '../asserts/umy-logo.png';


import VendorLoginModal from '../../vendor/auth/VendorLogin.js';
import VendorRegisterModal from '../../vendor/auth/VendorRegisterModal.js';

const Header = ({ pageInfo }) => {

   const [isModalOpen, setIsModalOpen] = useState(false);

   // modal register
   const [ModalOpen, setModalOpen] = useState(false);

   const [metatitle, setMetatitle] = useState("");
   const [meta_keywords, setMetakeywords] = useState("");
   const [meta_description, setMetadescription] = useState("");

   var SiteData = Siteinfo[0]['sitedata']

   const facebook = SiteData['facebook'];
   const twitter = SiteData['twitter'];

   const linkedin = SiteData['linkedin'];
   const instagram = SiteData['instagram'];
   const pinterest = SiteData['pinterest'];
   const youtube = SiteData['youtube'];
   const whatsapp = SiteData['whatsapp'];
   const contactemail = SiteData['email'];
   const contactphone = SiteData['phone'];


   var companyname = SiteData['companyname']

   const Getsettings = async (e) => {

      try {
         var postdata = { getdata: 1 };

         const response = await fetch(frontUrl + "get-front-settings", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),
         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  var settingsdata = data.data;
                  //  setCompanyname(settingsdata.companyname );


               } else {
                  //  ;
               }
            })
      } catch (error) {

      }

   }

   const setMetadata = async () => {

      if (typeof pageInfo == 'undefined' && typeof title == 'undefined') {

      } else if (typeof title != 'undefined') {

      } else {


         if (pageInfo == 'Login') {
            setMetatitle(companyname + 'Login')
            setMetakeywords('Login')
            setMetadescription('Login')
         }
         if (pageInfo == 'Register') {
            setMetatitle('Register')
            setMetakeywords('Register')
            setMetadescription('Register')
         }

         if (pageInfo == 'Dashboard') {
            setMetatitle('Dashboard')
            setMetakeywords('Dashboard')
            setMetadescription('Dashboard')
         }

         if (pageInfo == 'Termsconditions') {
            setMetatitle('Terms and Conditions')
            setMetakeywords('Terms and Conditions')
            setMetadescription('Terms and Conditions')
         }

         if (pageInfo == 'Privacypolicy') {
            setMetatitle('Privacy policy')
            setMetakeywords('Privacy policy')
            setMetadescription('Privacy policy')
         }

         if (pageInfo == 'Contactus') {
            setMetatitle('Contact Us')
            setMetakeywords('Contact Us')
            setMetadescription('Contact us')
         }
      }
   }

   useEffect(() => {
      // Getsettings();

      setMetadata();
   }, []);

   const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
   };


   const toggleRegisterModal = () => {
      setModalOpen(!ModalOpen);
   };

   return (

      <div>
         <MetaTags>
            <title>{companyname + ' - ' + metatitle}</title>



            <link rel="shortcut icon" href={SiteFavicon} />
            <meta id="meta-description" name="description" content={meta_description} />
            <meta id="meta-keywords" name="keywords" content={meta_keywords} />

            <meta id="og-title" property="og:title" content={companyname + ' - ' + metatitle} />
            <meta id="og-image" property="og:image" content="" />
         </MetaTags>






         <div className="  toast-notification-color" id="toast-notification"             >


            <div id="toast-body-notification" className="toast-body">

            </div>
         </div>



         <header>

            <div className="header top-header col-xs-12 col-md-12 col-sm-12">
               <div className="container">



                  <div className="top-left-menu">
                     <ul className="navbar-left navbar-left_header">
                        <li><a href={"tel:+" + contactphone} className="icon_header_text"  ><i className="fa fa-phone" aria-hidden="true"></i> {contactphone}</a></li>
                        |
                        <li><a href={contactemail} className="icon_header_text"><i className="fa fa-envelope" aria-hidden="true"></i> {contactemail}</a></li>
                     </ul>
                  </div>




                  <div className="top-right-menu">
                     <ul className="navbar-right">
                        <li> <span className="header_links">Follow Us :</span></li>

                        {(facebook) != '' ?
                           <li> <a title="Facebook" href={facebook} className="header_links"  ><i className="fa fa-facebook"></i></a> </li> : ''}


                        {(twitter) != '' ?
                           <li> <a title="Twitter" href={twitter} className="header_links"  ><i className="fa fa-twitter"></i></a> </li> : ''}

                        {(whatsapp) != '' ?
                           <li> <a title="WhatsApp" href={whatsapp} className="header_links"><i className="fa fa-whatsapp"></i></a> </li> : ''}

                        {(instagram) != '' ?
                           <li> <a title="Instagram" href={instagram} className="header_links"><i className="fa fa-instagram"></i></a> </li> : ''}

                        {(youtube) != '' ?
                           <li> <a title="Youtube" href={youtube} className="header_links" ><i className="fa fa-youtube"></i></a> </li> : ''}


                        {(pinterest) != '' ?
                           <li> <a title="Pinterest" href={pinterest} className="header_links"><i className="fa fa-pinterest"></i></a> </li> : ''}


                        {(linkedin) != '' ?
                           <li> <a title="Linkedin" href={linkedin} className="header_links"  ><i className="fa fa-linkedin"></i></a> </li> : ''}


                     </ul>
                  </div>



               </div>
            </div>



            <div className="header main-header col-xs-12 col-md-12 col-sm-12">
               <div className="container">
                  <div className="col-xs-12 col-md-2 col-sm-2 logo paddingzero">
                  </div>
                  <div className="col-xs-10 col-md-7 col-sm-6 header-search-con">
                     <form name="search" action="search" method="get" className="head-search-frm">
                     </form>
                  </div>
                  <div className="col-xs-12 col-md-3 col-sm-4 paddingzero">
                  </div>
               </div>
            </div>


            {/*  */}

            <div className="container">
               <div className="col-md-12 col-sm-12 col-xs-12 paddingzero">
                  <div className="col-xs-8 col-md-3 col-sm-3 paddingzero">
                     <div className="site-logo">
                        <a href="/"><img src={umy_logo} alt="amigoways" title="amigoways" className="amigoways-img img-responsive" /></a>
                     </div>
                  </div>
                  <div className="col-xs-12 col-md-5 col-sm-6 main-menu">
                     <ul className="menu-links">
                        <li>
                           <a className='home_page_text' href="/">Home</a>
                           <a className='home_page_text' href={'/about-us'}>About Us</a>
                           <a className='home_page_text' href={'/contact-us'}>Contact Us</a>
                        </li>
                     </ul>
                  </div>

                  <div className="col-xs-12 col-md-4 col-sm-3 text-right paddingzero">
                     <ul className="vendor-links">
                        <li><a href="/vendor/login" className="vendor-link">Vendor Login</a></li>

                        {/* <li>
                           <a href="#!" className="vendor-link" onClick={toggleModal}>
                              Vendor Login
                           </a>
                        </li>
                        <VendorLoginModal isOpen={isModalOpen} toggleModal={toggleModal} /> */}
                        
                        
                        {/* <li><a href="/register-vendor" className="vendor-link">Vendor Signup</a></li> */}

                        <li>
                           <a onClick={toggleRegisterModal}
                              className="vendor-link" >
                              Vendor Signup
                           </a>
                        </li>
                        <VendorRegisterModal isOpen={ModalOpen} toggleModal={toggleRegisterModal} />
                     </ul>
                  </div>
               </div>
            </div>

         </header>


      </div>




   );
};
export default Header;