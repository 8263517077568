import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";

 import {TextSpaceonlyValidation, MobileNumberValidation,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername , validEmailRegex } from '../Frontapi.js'; 

import {Lang} from "../Languages.js"

import {Blogdetailurl} from "../Siteurls.js"


import Header from "../includes/Header";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";



 
const Blog = () => {
  const navigate = useNavigate();

 
const [blogdata,setBlogdata]= useState([])
const [nPages, setnPages] = useState(1);
const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const [totalCount,settotalCount] = useState(0);

 const GetBlog = async (  value) => {
   
       var value = (typeof value !== 'undefined') ? value : currentPage;


      var postdata={ 'currentpage':value,'perpage':recordsPerPage };

        const response = await fetch(Url+"getblog", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              var blogdata=data.data
             


              

              setBlogdata(blogdata)

settotalCount(data.count)
                if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         

          
          } else{
                 
          }

           
       })


 
  };



 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetBlog()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetBlog()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetBlog(value)  
          
               
         
            
           

        }


   useEffect(() => {
  GetBlog()
   }, []);


  return (

      <div id="app">

      <Header />

      <Navbar />



          <div className="main-section"> 

    <div className="main-container"> 

              <h1 className="site-head-main-title ">{Lang('Latest News & Blog')}  </h1>




 
    <div className="row">

                       {  blogdata.map((blog) => (


        <div className="col-lg-4 col-md-6 mt-4 pt-2">
            <div className="blog-post rounded border">
                <div className="blog-img d-block overflow-hidden position-relative">
                    <img src={blog.image} alt=""/>
                    <div className="overlay rounded-top bg-dark"></div>
                    <div className="post-meta">
                        <a href="javascript:void(0)" className="text-light d-block text-right like"><i className="mdi mdi-heart"></i> 33</a>
                        <a href="javascript:void(0)" className="text-light read-more">Read More <i className="mdi mdi-chevron-right"></i></a>
                    </div>
                </div>
                <div className="content p-3">
                    <small className="text-muted p float-right">{blog.createddatetime}</small>
                    <small><a href="javascript:void(0)" className="text-primary">{blog.category}</a></small>
                    <h4 className="mt-2"><Link  to={Blogdetailurl+blog.url} className="text-dark title">{blog.title}</Link></h4>
                    <p className="text-muted mt-2">There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space.</p>
                   
                </div>
            </div> 
        </div> 
          ))  }  
        

      
         
    </div> 

{ (totalCount > recordsPerPage)?
       <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link"  onClick={goToPrevPage} href='#'> Previous </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }   className='page-link'  href='#'> {pgNumber} </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link"  onClick={goToNextPage} href='#'> Next </a>
      </li>
   </ul> : ''}
 

              </div> 


              </div>

 


      <Footer />

 
   </div>
 
  );
};
export default Blog;