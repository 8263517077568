import React, { useEffect, useState } from "react";
import { Notifyalert, requiredErrormessage, Url, UrlSplitter, checkpermissionredirect } from './../../Api.js';
import { useNavigate } from 'react-router-dom';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";

import { Allowsliders_view, Allowsliders_add, Allowsliders_edit, Allowsliders_delete } from './../../Permissions.js';
import { Getbannervideo } from "../../../frontend/GetDynamicImages.js";

const Addvideo = () => {
  const navigate = useNavigate();

  const [videoid] = useState(UrlSplitter(4));
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(1);
  const [image, setImage] = useState({ preview: "" });
  const [gifFile, setGifFile] = useState({ preview: "", data: null });
  const [videoFile, setVideoFile] = useState({ preview: "", data: null });

  const [titlereqerror, settitlereqerror] = useState("");

  const handleUserInput = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "title":
        setTitle(value);
        settitlereqerror("");
        break;
      case "status":
        setStatus(value);
        break;
      default:
        break;
    }
  };

  const handleVideoChange = (e) => {
    const video = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setVideoFile(video);
  };

  const handleGifChange = (e) => {
    const gif = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setGifFile(gif);
  };

  const Getvideo = async () => {
    const postdata = { _id: videoid };

    try {
      const response = await fetch(Url + "getbannervideo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postdata }),
      });

      const data = await response.json();

      if (data.success) {
        const videoData = data.data;
        setTitle(videoData.name);
        setGifFile({ preview: videoData.link }); 
        setVideoFile({ preview: videoData.image });
      } else {
        console.error("Failed to fetch video data");
      }
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };

  const Updatevideo = async () => {
    settitlereqerror("");

    let validationerror = 0;

    if (!title) {
      settitlereqerror(requiredErrormessage);
      validationerror = 1;
    }

    if (validationerror === 0) {
    //   const statusupdate = status === "1" || status === "undefined" ? "1" : "0";
    var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;


      const formData = new FormData();
      formData.append("name", title);
      formData.append("status", statusupdate);
      formData.append("_id", videoid);

      if (videoFile.data) {
        formData.append("videoFile", videoFile.data);
      }

      if (gifFile.data) {
        formData.append("gifFile", gifFile.data);
      }

      try {
        const response = await fetch(Url + "updatebannervideo", {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (data.success) {
          Notifyalert("success", data.message);
          navigate("/umy-admin-portal/bannervideo");
        } else {
          Notifyalert("error", data.message);
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  useEffect(() => {
    if (videoid) {
      Getvideo();
    }

    if (videoid) {
      checkpermissionredirect(Allowsliders_edit);
    } else {
      checkpermissionredirect(Allowsliders_add);
    }
  }, [videoid]);

  return (
    <div id="app">
      <Header title={videoid ? "Edit Video" : "Add Video"} />
      <Sidebar activePage="videos" activeChildPage="Add" />
      <div id="main">
        <Navbar />
        <div className="main-content container-fluid">
          <Pagetitle activePage={videoid ? "Edit Video" : "Add Video"} />
          <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">{videoid ? "Edit Video" : "Add Video"}</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form form-horizontal" autoComplete="off">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input className="form-control"  value={title} type="text" name="title" placeholder="Title" onChange={handleUserInput} required />
                            <p className="req-error-message">{titlereqerror}</p>
                          </div>

                          <div className="col-md-4">
                            <label>Video File (Image) <span className="field-required">*</span></label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input className="form-control" type="file" name="videoFile" onChange={handleVideoChange} required />
                            {videoFile.preview && <video width="100%" controls><source src={videoFile.preview} type="video/mp4" /></video>}
                          </div>

                          <div className="col-md-4">
                            <label>GIF File (Link) <span className="field-required">*</span></label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input className="form-control" type="file" name="gifFile" onChange={handleGifChange} required />
                            {gifFile.preview && <img src={gifFile.preview} alt="GIF Preview" />}
                          </div>

                          <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                          </div>
                          <div className="col-md-8 form-group">
                            <select className="form-control" name="status" onChange={handleUserInput}>
                              <option value="1" selected={status === 1 ? "selected" : ""}>
                                Active
                              </option>
                              <option value="0" selected={status === 0 ? "selected" : ""}>
                                InActive
                              </option>
                            </select>
                          </div>

                          <div className="col-sm-12 d-flex justify-content-end">
                            <a className="btn btn-primary me-1 mb-1" onClick={Updatevideo}>
                              Submit
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Addvideo;
