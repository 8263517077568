import React, { useEffect, useState } from "react";
import { baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../../Api.js';

import {
  Allowemailtemplates_view,
  Allowemailtemplates_edit
} from './../../Permissions.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";


const Emailtemplatelist = () => {





  const [emailtemplatesList, setemailtemplatesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  //const [notification, setnotification] = useState("");
  //const [notificationtype, setnotificationtype] = useState("");
  const [nPages, setnPages] = useState(1);
  const [pageNumbers, setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

  //const nPages = Math.ceil(emailtemplatesList.length / recordsPerPage)

  const goToNextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1)
      GetEmailtemplates()
    }
  }
  const goToPrevPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage - 1)
      GetEmailtemplates()

    }
  }


  const goToPage = (value) => {
    setCurrentPage(value)

    GetEmailtemplates(value)






  }

  const GetEmailtemplates = async (value) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
    //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

    var postdata = { 'currentpage': value, 'perpage': recordsPerPage };

    const response = await fetch(Url + "getemailtemplates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postdata }),

    }).then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setemailtemplatesList(data.data);
          if (data.count > 1) {
            //setnPages(data.count / recordsPerPage);

            setpageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1))

          }

        } else {

        }


      })



  };


  const DeleteConfirm = async (id) => {

    if (window.confirm("Are you sure?")) {
      var postdata = { 'id': id };
      const response = await fetch(Url + "deleteuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postdata }),

      }).then((response) => response.json())
        .then((data) => {
          if (data.success) {
            DeleteNotify()
            // setnotificationtype('success')
            //        setnotification(data.message)
          } else {
            DeleteNotify(data.message)

          }


        })

    }




  };



  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = emailtemplatesList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(emailtemplatesList.length / recordsPerPage)



  //pagination part



  useEffect(() => {
    GetEmailtemplates();
    checkpermissionredirect(Allowemailtemplates_view)


  }, []);


  //pagination part

  return (
    <div id="app">
      <Header title="Email templates List" />

      <Sidebar activePage="Email templates" />
      <div id="main">
        <Navbar />


        <div className="main-content container-fluid">

          <Pagetitle activePage="Email templates" />

          {ModalPopupDelete()}

          <section className="section">
            <div className="card">
              <div className="card-header">
                <span>Email templates</span>
              </div>
              <div className="card-body">

                <table className='table table-striped' id="table1">
                  <thead>
                    <tr>
                      <th>Name</th>

                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {emailtemplatesList.length > 0 ?
                    <tbody>
                      {emailtemplatesList.map((user) => (
                        <tr>
                          <td>{user.name}</td>

                          <td>
                            <span className="badge bg-success">Active</span>
                          </td>
                          <td className="table-action-sections">

                            {(Allowemailtemplates_edit == 1) ?

                              <a href={baseUrl + 'emailtemplates/edit/' + user._id} className="fa-edit-btn" > <i className="fa fa-pencil"></i></a> : ''}

                            <a style={{ display: 'none' }} onClick={() => DeleteConfirm(user._id)} className="fa-del-btn"    ><i className="fa fa-trash"></i> </a>

                          </td>
                        </tr>

                      ))}

                    </tbody> : <tbody><tr> <td colspan="3" align="center" >No Records Found </td> </tr></tbody>}
                </table>
              </div>
            </div>
            {pageNumbers.length > 1 ?

              <nav>
                <ul className='pagination pagination-primary justify-content-center'>
                  <li className="page-item prev-paging-single">
                    <a className="page-link"
                      onClick={goToPrevPage}
                      href='#'>
                      Previous
                    </a>
                  </li>
                  {pageNumbers.map(pgNumber => (
                    <li key={pgNumber}
                      className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                      <a onClick={(e) => goToPage(pgNumber)}
                        className='page-link'
                        href='#'>
                        {pgNumber}
                      </a>
                    </li>
                  ))}
                  <li className="page-item paging-next-single">
                    <a className="page-link"
                      onClick={goToNextPage}
                      href='#'>
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
              : ''

            }

          </section>

          <Footer />
        </div>


      </div>

    </div>


  );
};
export default Emailtemplatelist;