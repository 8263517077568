import React, { useEffect, useState } from "react";

import { Redirectlogin, Url, SiteFavicon } from '../Api.js';
import MetaTags from 'react-meta-tags';


import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';


const Header = ({ title, meta_description, meta_keywords }) => {
   Redirectlogin();

   const [companyname, setCompanyname] = useState("");



   const Getsettings = async (e) => {

      try {
         var postdata = { getdata: 1 };

         const response = await fetch(Url + "get-settings", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),
         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  var settingsdata = data.data;
                  setCompanyname(settingsdata.companyname);




               } else {
                  //   
               }


            })



      } catch (error) {

      }

   }



   useEffect(() => {
      Getsettings();
   }, []);



   return (

      <div>
         <MetaTags>
            <title>{companyname + ' - ' + title}</title>
            <link rel="shortcut icon" href={SiteFavicon} />

            <meta id="meta-description" name="description" content="Some description." />
            <meta id="og-title" property="og:title" content="MyApp" />
            <meta id="og-image" property="og:image" content="path/to/image.jpg" />
         </MetaTags>
         <NotificationContainer />



         <div className="  toast-notification-color" id="toast-notification"             >


            <div id="toast-body-notification" className="toast-body">
               Hi! You just scrolled the paragaraph.
            </div>
         </div>




      </div>




   );
};
export default Header;