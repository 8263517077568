import React, {useEffect, useState } from "react";
 
 import {Getserviceimage,TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
   import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowservices_view ,
    Allowservices_add ,
    Allowservices_edit ,
    Allowservices_delete 
} from './../../Permissions.js';
import { useNavigate } from "react-router-dom";

 

const Addservice = () => {

   const navigate = useNavigate();

const[serviceid] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 
const[content, setContent] = useState(""); 
 const[link, setLink] = useState(""); 

const[status, setStatus] = useState(1); 


const [image, setImage] = useState({preview:''})

const [category, setCategory] = useState('')

 const [subcategory, setSubcategory] = useState( )

const[notes, setNotes] = useState(""); 


const[serviceitems, setServiceitems] = useState([]); 




const [imagereqerror, setimagereqerror] = useState()
const [namereqerror, setnamereqerror] = useState("");
const [contentreqerror, setcontentreqerror] = useState("");
const [categoryreqerror, setcategoryreqerror] = useState("");
const [subcategoryreqerror, setsubcategoryreqerror] = useState("");
 const [linkreqerror, setlinkreqerror] = useState("");



const [categoriesList, setcategoriesList] = useState([]);

const [subcategoriesList, setsubcategoriesList] = useState([]);

const [notesreqerror, setnotesreqerror] = useState("");

 var [s_no]=useState(1)


const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        
        case 'name':
            setName(value);
            setnamereqerror('');
            break;
        case 'content':
            setContent(value);
             setcontentreqerror('');
            break;
        case 'notes':
            setNotes(value);
             setnotesreqerror('');
            break;    


         case 'link':
            setLink(value);
             setlinkreqerror('');
            break;
     
         case 'status':
            setStatus(value);
            break;  

             case 'category':
            setCategory(value);
            GetSubcategorieslist(value);
            break;  

               case 'subcategory':
            setSubcategory(value);
            break;  

        default:
            break;      
    }
 }
 

    const handlePictureChange = (e) => {

             const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            setImage({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
                setImage(img)
            } else {
                setimagereqerror("Invalid Image Format")
             }
        }



const assignServiceitems = async(serviceitems)=>{


setTimeout(function(){

  Object.keys(serviceitems).forEach(function(key) {
    //(async function() { 

 
 
 // console.log("serviceitemname"+serviceitems[key]._id)

  var id = "serviceitemname"+serviceitems[key]._id
   var priceid = "serviceitemprice"+serviceitems[key]._id


        document.getElementById(id).value=serviceitems[key].name

         document.getElementById(priceid).value=serviceitems[key].price

    
 // document.getElementById(id).value=serviceitems[key].price


  // document.getElementsByClassName('serviceitemname')[0].value=serviceitems[key].name



 
//})()
              })

},500)
            



}

 
 const Getservice = async (e) => {
   

     var postdata={_id:serviceid};

     const response = await fetch(Url+"getservices", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              

               var servicedata=data.data.servicedata;

               var serviceitems = data.data.serviceitems;

                setServiceitems(serviceitems);
                 


                    
                setName(servicedata.name);
                setLink(servicedata.link);
                  setContent(servicedata.content);
                  setNotes(servicedata.notes);

 


                    setCategory(servicedata.categoryId);

                    setSubcategory(servicedata.subcategoryId);

 
 
                             setImage({preview:  servicedata.image  }  )
                           
                       

    GetSubcategorieslist(servicedata.categoryId);


         assignServiceitems(serviceitems)
          } else{
              //   
          }

           
       })

 

 } 



 
  const Updateservice = async (e) => {

    setnamereqerror('');
     setcontentreqerror('');
     setlinkreqerror('');

 
    var validationerror = 0;
 
    if(checkEmptyUndefined(name) == false){
        setnamereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(content) == false){
        setcontentreqerror(requiredErrormessage)
        validationerror=1;
    } 

    if(checkEmptyUndefined(link) == false){
      //  setlinkreqerror(requiredErrormessage)
     //   validationerror=1;
    }   

       if(checkEmptyUndefined(category) == false){
        setcategoryreqerror(requiredErrormessage)
        validationerror=1;
    }   

    if(checkEmptyUndefined(subcategory) == false){
        setsubcategoryreqerror(requiredErrormessage)
        validationerror=1;
    }  
      
if(serviceid == '' || typeof serviceid == 'undefined'){

if(typeof image.data  == 'undefined' || image.data == ''){
                setimagereqerror(requiredErrormessage)
                validationerror=1
            }
    

}

      


 
     if(validationerror == 0){ 

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;


         

            //serviceitems
          const form = document.querySelector('form');
            const serviceitemdata = new FormData(form);


            var   serviceitemnamevalues=[]
            var   serviceitempricevalues=[]
            var   serviceitemidvalues=[]


            serviceitemdata.forEach(function(value, key) {

                if(key == 'serviceitemname[]'){

                 var values = {'value' :value}

                  serviceitemnamevalues.push(value)
                }
                if(key == 'serviceitemprice[]'){

                 var values = {'value' :value}

                  serviceitempricevalues.push(value)
                }

                if(key == 'serviceitemid[]'){

                 var values = {'value' :value}

                  serviceitemidvalues.push(value)
                }


            } )
            //serviceitems
 




       let formData = new FormData()
            formData.append('name', name)
            formData.append('content', content)
            formData.append('link', link)
            formData.append('image', image.data)
            formData.append('status', statusupdate)
            formData.append('_id', serviceid)

            formData.append('category', category)
            formData.append('subcategory', subcategory)

            formData.append('notes',notes)




            formData.append('serviceitemname',serviceitemnamevalues)
            formData.append('serviceitemprice',serviceitempricevalues)
            formData.append('serviceitemid',serviceitemidvalues)
 
            
            
  
      const response = await fetch(Url+"updateservice", {
        method: "POST",
       
        body: formData
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)
                                   navigate('/umy-admin-portal/services');

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };



 const GetCategorieslist = async ( ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

 
      var postdata={'currentpage':1,'perpage':10000};

        const response = await fetch(Url+"getcategories", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           setcategoriesList(data.data );
         
         
          } else{
                 
          }

           
       })


 
  };


 const GetSubcategorieslist = async (catval='' ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)
    
    var category = (catval != '') ? catval : category;
 
      var postdata={'currentpage':1,'perpage':10000, 'category':category };

        const response = await fetch(Url+"getsubcategories", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           setsubcategoriesList(data.data );
         
         
          } else{
                 
          }

           
       })


 
  };




 


  const AddServiceItem= async (  ) => {

 
 
 
 
 var d1 = document.getElementById('extraitems');
d1.insertAdjacentHTML('beforeend', '<div class="col-md-12 row"><div class="col-md-2"><label>Name <span class="field-required">*</span></label></div><div class="col-md-3 form-group"><input  class="form-control" value=""   name="serviceitemname[]" /></div><div class="col-md-2"><label>Price <span class="field-required">*</span></label></div><div class="col-md-3 form-group"><input  class="form-control" value=""   name="serviceitemprice[]" /><input type="hidden" name="serviceitemid[]" value="" /></div><div class="col-md-2"  > <i class="fa fa-times"></i> </div></div>');

 
 
 
}
    
useEffect(() => {

    GetCategorieslist();


if(typeof serviceid != 'undefined' && serviceid !=''){
    Getservice(serviceid);
       

}

if(typeof serviceid != 'undefined' && serviceid !=''){
    checkpermissionredirect(Allowservices_edit)
}else{
    checkpermissionredirect(Allowservices_add)

}

  
  },[] ); 

   return (

       <div id="app">
   <Header title={typeof serviceid !== 'undefined'?"Edit Service":"Add Service"} />
   <Sidebar activePage="Services"  activeChildPage="Add" />
   <div id="main">
      <Navbar />
      <div className="main-content container-fluid">
         <Pagetitle activePage={typeof serviceid !== 'undefined'?"Edit Service":"Add Service"}/>
         <div className="row match-height">
            <div className="col-md-8 col-12 mx-auto">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">{typeof serviceid !== 'undefined'?"Edit Service":"Add Service"}</h4>
                  </div>
                  <div className="card-content">
                     <div className="card-body">
                        <form className="form form-horizontal" autocomplete="off">
                           <div className="form-body">

                              <div className="row">
                                 <div className="col-md-4">
                                    <label>Title <span className="field-required">*</span></label>
                                 </div>




                                 <div className="col-md-8 form-group">
                                    <input className="form-control"  value={name}  type="text" name="name" placeholder="Title"  
                                       onChange={(e) => handleUserInput(e)}
                                    required/>
                                    <p className="req-error-message">{namereqerror}</p>
                                 </div>



                                <div className="col-md-4">
                                    <label>Category <span className="field-required">*</span></label>
                                 </div>

                                 <div className="col-md-8 form-group">
                                    <select className="form-control"  name="category"  onChange={(e) =>
                                       handleUserInput(e)}> 
                                       <option value="">Choose</option>
                                       {categoriesList.map((categorylist) => (
                                       <option value={categorylist._id}  selected={categorylist._id == category ? 'selected' :''}  >{categorylist.name}</option>
                                       ))}
                                    </select>
                                    <p className="req-error-message">{categoryreqerror}</p>
                                 </div>




                                 <div className="col-md-4">
                                    <label>Subcategory <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <select className="form-control"  name="subcategory"  onChange={(e) =>
                                       handleUserInput(e)}> 
                                       <option value="">Choose</option>
                                       {subcategoriesList.map((subcategorylist) => (
                                       <option value={subcategorylist._id}  selected={subcategorylist._id == subcategory ? 'selected' :''}  >{subcategorylist.name}</option>
                                       ))}
                                    </select>
                                    <p className="req-error-message">{subcategoryreqerror}</p>
                                 </div>





                                 <div className="col-md-4">
                                    <label>Content <span className="field-required">*</span></label>
                                 </div>

                                 <div className="col-md-8 form-group">
                                    <ReactQuill value={content}  onChange={setContent} name="content" placeholder="Content" rows="10" className="textarea-contact-input MustContactEnterField mb-25"  />
                                    <p className="req-error-message">{contentreqerror}</p>
                                 </div>



                                 <div className="col-md-4">
                                    <label>Notes <span className="field-required"></span></label>
                                 </div>

                                 <div className="col-md-8 form-group">
                                    <ReactQuill value={notes}  onChange={setNotes} name="content" placeholder="Content" rows="10" className="textarea-contact-input MustContactEnterField mb-25"  />
                                    <p className="req-error-message">{notesreqerror}</p>
                                 </div>



                                 




                                 <div className="col-md-4">
                                    <label>Link <span className="field-required"></span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <input className="form-control"   type="text" name="link" placeholder="Link" value={link} 
                                       onChange={(e) => handleUserInput(e)}
                                    required/>
                                    <p className="req-error-message">{linkreqerror}</p>
                                 </div>
                                 <div className="col-md-4">
                                    <label>Image <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <input className="form-control"   type="file" name="image" placeholder="Link"   
                                       onChange={(e) => handlePictureChange(e)}
                                    required/>
                                    {(image.preview  != '') ? <img className="img-responsive-amigo"  src ={image.preview}  />  : '' }
                                    <p className="req-error-message">{imagereqerror}</p>
                                 </div>
                                 <div className="col-md-4">
                                    <label>Status <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                                    <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                                    <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                                    </select>
                                 </div>


                                 <p>Items </p>






                                 
                                









                                 
                              </div>


                              <div className="row">


                            {  serviceitems.length > 0 ? 


                                <div>
                                 {  serviceitems.map((serviceitemSingle) => ( 


                            <div className="col-md-12 row">





                                 <div className="col-md-2">
                                    <label>  Name <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-3 form-group">
                                    <input  className="form-control"  id={'serviceitemname'+serviceitemSingle._id}  name="serviceitemname[]" />
                                 </div>
                                 <div className="col-md-2">
                                    <label>Price <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-3 form-group">
                                    <input  className="form-control"    id={'serviceitemprice'+serviceitemSingle._id}  name="serviceitemprice[]" />
                                 </div>
                                 <div className="col-md-2">

                                 { (s_no == 1) ? 
                                 <a onClick={AddServiceItem} className="btn btn-success"> Add </a> : '' }

                                 <input type="hidden" value={s_no++} />

                                  <input type="hidden" name="serviceitemid[]" value={serviceitemSingle._id} />


                                 </div>




                                 </div>

                                  ))  }  
                                  </div>



                            :



                            <div className="col-md-12 row">
                                 <div className="col-md-2">
                                    <label>Name <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-3 form-group">
                                    <input  className="form-control"  id="serviceitemname"  name="serviceitemname[]" />
                                 </div>
                                 <div className="col-md-2">
                                    <label>Price <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-3 form-group">
                                    <input  className="form-control"    id="serviceitemprice"  name="serviceitemprice[]" />
                                 </div>
                                 <div className="col-md-2">

                                 <a onClick={AddServiceItem} className="btn btn-success"> Add </a>


                                  <input type="hidden" name="serviceitemid[]" value="" />

                                 </div>

                                 </div>

                             }



                               <div id="extraitems">

                                  </div>






                              </div>

                              <div className="row">
                              <div className="col-sm-12 d-flex justify-content-end">
                                    <a  className="btn btn-primary me-1 mb-1" onClick={Updateservice}>Submit</a>
                                 </div>
                                 </div>



                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   </div>
</div>
                   
   
 
  );
};
export default Addservice;