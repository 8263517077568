import React, { useEffect, useState } from "react";
import {
   Link,
   useNavigate
} from "react-router-dom";
import { Getserviceimage, baseUrl, Url, ModalPopupDelete, DeleteNotify, checkpermissionredirect } from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";
import {
   Allowservices_view,
   Allowservices_add,
   Allowservices_edit,
   Allowservices_delete
} from './../../Permissions.js';


const Servicelist = () => {





   const [serviceList, setserviceList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage] = useState(10);

   //const [notification, setnotification] = useState("");
   //const [notificationtype, setnotificationtype] = useState("");
   const [nPages, setnPages] = useState(1);
   const [pageNumbers, setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

   //const nPages = Math.ceil(serviceList.length / recordsPerPage)

   const goToNextPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage + 1)
         GetService()
      }
   }
   const goToPrevPage = () => {
      if (currentPage !== nPages) {
         setCurrentPage(currentPage - 1)
         GetService()

      }
   }


   const goToPage = (value) => {
      setCurrentPage(value)

      GetService(value)






   }

   const GetService = async (value) => {

      // let formData = new FormData()
      //  formData.append('currentpage', currentPage)
      //   formData.append('perpage', recordsPerPage)

      value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata = { 'currentpage': value, 'perpage': recordsPerPage };

      const response = await fetch(Url + "getservices", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ postdata }),

      }).then((response) => response.json())
         .then((data) => {
            if (data.success) {

               var servicedata = data.data;



               setserviceList(servicedata);










               if (data.count > 1) {
                  //setnPages(data.count / recordsPerPage);

                  setpageNumbers([...Array(Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1))

               }

            } else {

            }


         })



   };


   const DeleteConfirm = async (id) => {

      if (window.confirm("Are you sure?")) {
         var postdata = { 'id': id };
         const response = await fetch(Url + "deleteservice", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ postdata }),

         }).then((response) => response.json())
            .then((data) => {
               if (data.success) {
                  DeleteNotify()
               } else {
                  DeleteNotify(data.message);
               }

               GetService();


            })

      }




   };



   // const indexOfLastRecord = currentPage * recordsPerPage;
   //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
   //  const currentRecords = serviceList.slice(indexOfFirstRecord, indexOfLastRecord);
   //  const nPages = Math.ceil(serviceList.length / recordsPerPage)



   //pagination part



   useEffect(() => {
      GetService();

      checkpermissionredirect(Allowservices_view)



   }, []);

   //pagination part

   return (
      <div id="app">
         <Header title="Service List" />

         <Sidebar activePage="Services" activeChildPage="List" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">

               <Pagetitle activePage="Service" />


               <section className="section">
                  <div className="card">
                     <div className="card-header">
                        <span>Service</span>


                        {(Allowservices_add == 1) ?

                           <div className="pull-right"><a href={baseUrl + 'services/add'} className="btn btn-success me-1 mb-1">Add Service</a></div> : ''}

                     </div>
                     <div className="card-body">


                        <table className='table table-striped' id="table1">
                           <thead>
                              <tr>
                                 <th>Name</th>

                                 <th>Image</th>

                                 <th>Link</th>
                                 <th>Status</th>
                                 <th>Action</th>
                              </tr>
                           </thead>

                           {serviceList.length > 0 ?


                              <tbody>

                                 {serviceList.map((serviceSingle) => (
                                    <tr>
                                       <td>{serviceSingle.name}</td>



                                       <td><img src={serviceSingle.image} className="img-responsive w-100px" /></td>

                                       <td>{serviceSingle.link}</td>


                                       <td>
                                          {(serviceSingle.status == 1) ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">InActive</span>}

                                       </td>
                                       <td className="table-action-sections">




                                          {(Allowservices_edit == 1) ?
                                             <Link to={baseUrl + 'services/edit/' + serviceSingle._id} className="fa-edit-btn" > <i className="fa fa-pencil"></i></Link> : ''}

                                          {(Allowservices_delete == 1) ?
                                             <a onClick={() => DeleteConfirm(serviceSingle._id)} className="fa-del-btn"   ><i className="fa fa-trash"></i> </a>
                                             : ''}

                                       </td>
                                    </tr> : ''
                        
                           
                          
                     ))  }

                              </tbody> : <tbody><tr> <td colspan="5" align="center" >No Records Found </td> </tr></tbody>}


                        </table>
                     </div>
                  </div>

                  {pageNumbers.length > 1 ?
                     <nav>
                        <ul className='pagination pagination-primary justify-content-center'>
                           <li className="page-item prev-paging-single">
                              <a className="page-link"
                                 onClick={goToPrevPage}
                                 href='#'>
                                 Previous
                              </a>
                           </li>
                           {pageNumbers.map(pgNumber => (
                              <li key={pgNumber}
                                 className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                                 <a onClick={(e) => goToPage(pgNumber)}
                                    className='page-link'
                                    href='#'>
                                    {pgNumber}
                                 </a>
                              </li>
                           ))}
                           <li className="page-item paging-next-single">
                              <a className="page-link"
                                 onClick={goToNextPage}
                                 href='#'>
                                 Next
                              </a>
                           </li>
                        </ul>
                     </nav> : ''}




               </section>

               <Footer />
            </div>


         </div>

      </div>


   );
};
export default Servicelist;