import React, { useState, useEffect } from "react";

import {
    Link,
    useNavigate
} from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Lang } from "../Languages.js"

import { Redirectlogin, Siteinfo, frontUrl, Url, SiteFavicon } from '../Frontapi.js';

import Header from "../includes/Header";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";

import waterpurifer from '../asserts/water-purifier-image.png';
import ac_img from '../asserts/ac-image.png';
import home_banner from '../asserts/homepage_image.png';
import add_01 from '../asserts/add-01.png';
import add_02 from '../asserts/add-02.png';
import add_03 from '../asserts/add-03.png';
import 'font-awesome/css/font-awesome.min.css';

import './Home.css';

import Ac_image from '../asserts/ac_image-removebg-preview.png'
import googleplayicon from '../asserts/Play Store Icon 2.png';
import applestoreicon from '../asserts/Apple store Icon 2.png';
import faq_img from '../asserts/faq_img.png';
import callus from '../asserts/callus.png';
import whatsapps from '../asserts/whatsapp.png';
import emailimg from '../asserts/emailimg.png';
import followusimg from '../asserts/followus.png';
import socialicons from '../asserts/folowusiconimg.png';
import addressimg from '../asserts/address.png';
import acservice from '../asserts/ac_service.png'
import acservice_two from '../asserts/acservice2.png';

const services = Siteinfo[1]['servicedata'];
var SiteData = Siteinfo[0]['sitedata']
const facebook = SiteData['facebook'];
const twitter = SiteData['twitter'];

const linkedin = SiteData['linkedin'];
const instagram = SiteData['instagram'];
const pinterest = SiteData['pinterest'];
const youtube = SiteData['youtube'];
const whatsapp = SiteData['whatsapp'];
var companyname = SiteData['companyname']


const Home = () => {

    const [name, setName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [errors, setErrors] = useState({});

    const [error, setError] = useState('');


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    // const handleInputChange = (event) => {
    //     setEmail(event.target.value);
    // };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     if (email.trim() === '') {
    //         setMessage('Please enter a valid email address.');
    //         return;
    //     }

    //     const postData = {
    //         postdata: {
    //             email: email,
    //         },
    //     };

    //     try {

    //         const response = await fetch('http://localhost:5090/subscribe-user', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(postData),
    //         });

    //         const result = await response.json();

    //         if (response) {
    //             if (result.success) {
    //                 setMessage(result.message);
    //             } else {
    //                 setMessage(result.message);
    //             }
    //         } else {
    //             setMessage('An error occurred. Please try again later.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         setMessage('An error occurred. Please try again later.');
    //     }
    // };


    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     setError('');


    //     if (email.trim() === '') {
    //         setError('Please enter a valid email address.');
    //         return;
    //     }

    //     const postData = {
    //         postdata: {
    //             email: email,
    //         },
    //     };

    //     try {
    //         const response = await fetch('http://localhost:5090/subscribe-user', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(postData),
    //         });

    //         const result = await response.json();

    //         if (response.ok) {
    //             if (result.success) {

    //                 setEmail('');
    //                 setError('');
    //                 alert(result.message);
    //             } else {
    //                 setError(result.message);
    //             }
    //         } else {
    //             setError('An error occurred. Please try again later.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         setError('An error occurred. Please try again later.');
    //     }
    // };
    const handleSubmit = async (event) => {
        event.preventDefault();

        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (email.trim() === '') {
            setError('Please Enter your email address.');
            return;
        }

        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        const postData = {
            postdata: {
                email: email,
            },
        };

        try {
            // const response = await fetch('http://localhost:5090/subscribe-user', {
            const response = await fetch(Url + 'subscribe-user', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            const result = await response.json();

            if (response.ok) {
                if (result.success) {
                    setEmail('');
                    setError('');
                    alert(result.message);
                } else {
                    setError(result.message);
                }
            } else {
                setError('An error occurred. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again later.');
        }
    };


    const handleContactInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setContactEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'subject':
                setSubject(value);
                break;
            case 'message':
                setContactMessage(value);
                break;
            default:
                break;
        }
    };


    // const handleContactformSubmit = (event) => {
    //     event.preventDefault();

    //     let formErrors = {};


    //     if (name.trim() === '') {
    //         formErrors.name = 'Please enter your name.';
    //     }
    //     if (contactEmail.trim() === '') {
    //         formErrors.email = 'Please enter a valid email address.';
    //     }
    //     if (phone.trim() === '') {
    //         formErrors.phone = 'Please enter your phone number.';
    //     }
    //     if (subject.trim() === '') {
    //         formErrors.subject = 'Please enter a subject.';
    //     }
    //     if (contactMessage.trim() === '') {
    //         formErrors.message = 'Please enter your message.';
    //     }


    //     if (Object.keys(formErrors).length > 0) {
    //         setErrors(formErrors);
    //         return;
    //     }


    //     setErrors({});

    //     const postData = {
    //         postdata: {
    //             name: name,
    //             email: contactEmail,
    //             mobile: phone,
    //             subject: subject,
    //             message: contactMessage,
    //         }
    //     };


    //     fetch('http://localhost:5090/create-new-inquiry', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postData),
    //     })
    //         .then((response) => response.json())
    //         .then((result) => {
    //             if (result.success) {

    //                 alert(result.message);

    //                 setName('');
    //                 setContactEmail('');
    //                 setPhone('');
    //                 setSubject('');
    //                 setContactMessage('');
    //                 setErrors({});
    //             } else {

    //                 alert(result.message);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //             alert('An error occurred. Please try again later.');
    //         });
    // };

    const handleContactformSubmit = (event) => {
        event.preventDefault();

        let formErrors = {};


        if (name.trim() === '') {
            formErrors.name = 'Please enter your name.';
        }

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (contactEmail.trim() === '') {
            formErrors.email = 'Please enter your email address.';
        } else if (!emailPattern.test(contactEmail)) {
            formErrors.email = 'Please enter a valid email address.';
        }


        const phonePattern = /^[0-9]{10}$/;
        if (phone.trim() === '') {
            formErrors.phone = 'Please enter your phone number.';
        } else if (!phonePattern.test(phone)) {
            formErrors.phone = 'Please enter a valid phone number.';
        }


        if (subject.trim() === '') {
            formErrors.subject = 'Please enter a subject.';
        }


        if (contactMessage.trim() === '') {
            formErrors.message = 'Please enter your message.';
        }


        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }


        setErrors({});

        const postData = {
            postdata: {
                name: name,
                email: contactEmail,
                mobile: phone,
                subject: subject,
                message: contactMessage,
            }
        };

        fetch('http://localhost:5090/create-new-inquiry', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    alert(result.message);


                    setName('');
                    setContactEmail('');
                    setPhone('');
                    setSubject('');
                    setContactMessage('');
                    setErrors({});
                } else {
                    alert(result.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred. Please try again later.');
            });
    };


    const navigate = useNavigate();




    useEffect(() => {
        //   checkss()
        //   Redirectlogin();
    }, []);




    return (

        <div id="app">

            <Header />

            <div className="header_line"></div>


            <div className="container">
                <div className="col-md-12 col-sm-12 col-xs-12 banner paddingzero">
                    <div className="col-md-6 col-sm-6 col-xs-12 paddingzero">
                        <h1 className="home_page_heading">Home services at your doorstep</h1>
                        <div className="banner-border"></div>
                        <p className="home_page_subparagraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        <div className="normal-box">
                            <div className="what-are-you-looking-for">
                                {/* <h5>What are you looking for?</h5> */}
                                <div className="col-md-12 col-sm-12 col-xs-12 box-top paddingzero">
                                    <a className="col-md-6 col-sm-6 col-xs-12 ">
                                        {/* <div className="normal-box-shadow"> */}
                                        {/* <span>AC & Appliance Repair</span> */}
                                        {/* <img src={frontUrl+'assets/images/frontend/ac-image.png'}/> */}
                                        {/* <img src={ac_img} /> */}

                                        {/* </div> */}
                                    </a>
                                    <a className="col-md-6 col-sm-6 col-xs-12">
                                        {/* <div className="normal-box-shadow"> */}
                                        {/* <span>Native Water Purifier</span> */}
                                        {/* <img src={frontUrl+'assets/images/frontend/water-purifier-image.png'}/> */}
                                        {/* <img src={waterpurifer} /> */}

                                        {/* </div> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 banner-img">
                        {/* <img src={frontUrl+'assets/images/frontend/home-banner.png'}/> */}
                        <img src={home_banner} />

                    </div>
                </div>
            </div>

            <div className="services-background">
                <div className="container">
                    <div className="col-md-12 col-sm-12 col-xs-12 services-icons">

                        {(services.slice(0, 4)).map((service) => (
                            <a className="col-md-2 col-sm-2 col-xs-12" key={service.id}>
                                <img src={service.image} alt={service.name} />
                                <p className="servicetext">{service.name}</p>
                            </a>
                        ))}
                    </div>
                </div>

                {/* <div className="container">
                    <div className="col-md-12 col-sm-12 col-xs-12 services-icons">

                        {(services).map((service) => (


                            <a className="col-md-2 col-sm-2 col-xs-12">
                                <img src={service.image} />
                                <p className="servicetext">{service.name}</p>
                            </a>

                        ))}


                    </div>
                </div> */}
            </div>

            <div className="container">
                <div className="row add-banner">

                    <div className="col-md-4 col-sm-12 col-xs-12 d-flex flex-column flex-md-row bg_color card bannercard">
                        <div className="card-text">
                            <p className="home_page_subpara">Save on electricity bills with power saver AC service</p>
                            <p className="home_page_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                        <img src={acservice} alt="image1" className="card-img" />
                    </div>


                    <div className="col-md-4 col-sm-12 col-xs-12 d-flex flex-column flex-md-row bg_color card bannercard">
                        <div className="card-text">
                            <p className="home_page_subpara">Galti reh gayi toh painting free</p>
                            <p className="home_page_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                        <img src={acservice_two} alt="image2" className="card-img" />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 d-flex flex-column flex-md-row bg_color card bannercard">
                        <div className="card-text">
                            <p className="home_page_subpara">Elevate your wedding glow</p>
                            <p className="home_page_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                        <img src={acservice} alt="image3" className="card-img" />
                    </div>
                </div>
            </div>






            {/*  */}
            {/* <div className="contrainer-fluid home_service_bg_img">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="ac_doorstep">Home services at your doorstep</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="  home_doorsteo_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <img src={googleplayicon} className="store_icon_img" />
                    </div>
                    <div className="col-lg-2">

                        <img src={applestoreicon} className="store_icon_img" />

                    </div>
                </div>
            </div> */}

            {/* FAQ CONTAINER */}

            <div className="container">
                <div className="home_page_faq">
                    <p className="faq_faq">FAQ</p>
                    <p className="faq_text_heading">Frequently Asked Questions </p>
                    <p className="faq_text">Welcome to our FAQ section. Here, you'll find answers to some of the most</p>
                </div>
            </div>

            <div className="container">
                <div className="col-md-12 col-sm-12 col-xs-12 banner paddingzero">
                    <div className="col-md-6 col-sm-6 col-xs-12 paddingzero">
                        <img class="w-100 faq_image" src={faq_img} alt="Image of a person thinking" />

                        <div className="normal-box">
                            <div className="what-are-you-looking-for">
                                <div className="col-md-12 col-sm-12 col-xs-12 box-top paddingzero">
                                    <a className="col-md-6 col-sm-6 col-xs-12 ">

                                    </a>
                                    <a className="col-md-6 col-sm-6 col-xs-12">

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 banner-img">
                        <div class="accordion" id="accordionExample">

                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header">
                                    <button class="accordion-button rounded-icon" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.<i class="fa fa-chevron-down me-2"></i>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum at odio id mattis. Duis vehicula tortor purus, nec tristique sapien bibendum sit amet. In maximus, lorem sed semper semper, nisi leo malesuada lacus, sed interdum augue lorem vitae augue. Donec nec euismod risus, laoreet vestibulum orci. </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="accordion-item mb-3 ">
    <h2 class="accordion-header">
        <button class="accordion-button rounded-icon collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            1.What is Dhanakuti?
        </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <p>Dhanakuti is Indiaâ€™s most trusted digital platform for investing in gold. It
                allows you to buy, store, and sell digital gold securely, offering a transparent
                and user-friendly experience. With Dhanakuti, you can track your gold
                investments in real-time and enjoy the flexibility of physical
                delivery if desired.</p>
        </div>
    </div>
</div> */}

                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header">
                                    <button class="accordion-button rounded-icon collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i class="fa fa-chevron-down me-2"></i>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum at odio id mattis. Duis vehicula tortor purus, nec tristique sapien bibendum sit amet. In maximus, lorem sed semper semper, nisi leo malesuada lacus, sed interdum augue lorem vitae augue. Donec nec euismod risus, laoreet vestibulum orci.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed rounded-icon" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        3.Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i class="fa fa-chevron-down me-2"></i>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum at odio id mattis. Duis vehicula tortor purus, nec tristique sapien bibendum sit amet. In maximus, lorem sed semper semper, nisi leo malesuada lacus, sed interdum augue lorem vitae augue. Donec nec euismod risus, laoreet vestibulum orci.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed rounded-icon" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        4. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i class="fa fa-chevron-down me-2"></i>
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum at odio id mattis. Duis vehicula tortor purus, nec tristique sapien bibendum sit amet. In maximus, lorem sed semper semper, nisi leo malesuada lacus, sed interdum augue lorem vitae augue. Donec nec euismod risus, laoreet vestibulum orci.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item ">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed rounded-icon" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        5. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i class="fa fa-chevron-down me-2"></i>
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum at odio id mattis. Duis vehicula tortor purus, nec tristique sapien bibendum sit amet. In maximus, lorem sed semper semper, nisi leo malesuada lacus, sed interdum augue lorem vitae augue. Donec nec euismod risus, laoreet vestibulum orci.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>






            {/* Contactus */}

            <div className="container-fluid">
                <div className="col-md-12 col-sm-12 col-xs-12   contactus_homepage">
                    <div className="col-md-6 col-sm-6 col-xs-12 contact_forms">
                        <p className="homepage_contactus">Contact Us</p>
                        <div className="line"></div>

                        <div className="form-row">
                            <div className="form-group">
                                <input name="name" className={`form-control homepage_contact_input ${errors.name ? 'is-invalid' : ''}`} type="text" value={name} onChange={handleContactInputChange} placeholder="Your Name" aria-label="name" />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>

                            <div className="form-group ">

                                <input className={`form-control homepage_contact_input ${errors.email ? 'is-invalid' : ''}`}
                                    autocomplete="off" type="text" name="email" class="form-control" id="email" placeholder="Email ID" value={contactEmail}
                                    onChange={handleContactInputChange} />

                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>
                        </div>




                        <div className="form-row">
                            <div className="form-group">
                                <input name="phone" className={`form-control homepage_contact_input ${errors.phone ? 'is-invalid' : ''}`} type="text" value={phone} onChange={handleContactInputChange} placeholder="Phone Number" aria-label="phone" />
                                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            </div>

                            <div className="form-group">
                                <input name="subject" className={`form-control homepage_contact_input ${errors.subject ? 'is-invalid' : ''}`} type="text" value={subject} onChange={handleContactInputChange} placeholder="Subject" aria-label="subject" />
                                {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                            </div>
                        </div>

                        <div className="form-group">
                            <textarea name="message" className={`form-control homepage_contact_input ${errors.message ? 'is-invalid' : ''}`} value={contactMessage} onChange={handleContactInputChange} placeholder="Your Message" rows={6} aria-label="message"></textarea>
                            {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                        </div>

                        <div className="form-group submit-button-container text-center">
                            <button className="contactus_submit_button" onClick={handleContactformSubmit}>
                                Submit
                            </button>
                        </div>



                        <div className="normal-box">

                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 banner-img homepage_contactus_secondcolumn">
                        <div className="contact-detail">
                            <div className="icon-container" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={callus} alt="Call Us" />
                            </div>
                            <div className="text-container">
                                <p>Call Us</p>
                                <p>+14845219776</p>
                                <p>+14845219776</p>
                            </div>
                        </div>

                        {/* WhatsApp */}
                        <div className="contact-detail">
                            <div className="icon-container" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={whatsapps} alt="WhatsApp" />
                            </div>
                            <div className="text-container">
                                <p>WhatsApp</p>
                                <p>+14845219776</p>
                                <p>+14845219776</p>
                            </div>
                        </div>

                        {/* Email */}
                        <div className="contact-detail">
                            <div className="icon-container" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={emailimg} alt="Email Us" />
                            </div>
                            <div className="text-container">
                                <p>Email</p>
                                <p>support@umy.com</p>
                                <p>support@company.com</p>
                            </div>
                        </div>

                        {/* Follow Us */}
                        <div className="contact-detail">
                            <div className="icon-container" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={followusimg} alt="Follow Us" />
                            </div>
                            <div className="text-container">
                                <p>Follow Us</p>
                                <p className="social-icons">
                                    {(facebook) != '' ?
                                        <li> <a title="Facebook" href={facebook} className="header_links"  ><i className="fa fa-facebook"></i></a> </li> : ''}


                                    {(twitter) != '' ?
                                        <li> <a title="Twitter" href={twitter} className="header_links"  ><i className="fa fa-twitter"></i></a> </li> : ''}

                                    {(whatsapp) != '' ?
                                        <li> <a title="WhatsApp" href={whatsapp} className="header_links"><i className="fa fa-whatsapp"></i></a> </li> : ''}

                                    {(instagram) != '' ?
                                        <li> <a title="Instagram" href={instagram} className="header_links"><i className="fa fa-instagram"></i></a> </li> : ''}

                                    {(youtube) != '' ?
                                        <li> <a title="Youtube" href={youtube} className="header_links" ><i className="fa fa-youtube"></i></a> </li> : ''}


                                    {(pinterest) != '' ?
                                        <li> <a title="Pinterest" href={pinterest} className="header_links"><i className="fa fa-pinterest"></i></a> </li> : ''}


                                    {(linkedin) != '' ?
                                        <li> <a title="Linkedin" href={linkedin} className="header_links"  ><i className="fa fa-linkedin"></i></a> </li> : ''}

                                    {/* <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                    <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                    <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a> */}

                                </p>
                            </div>
                        </div>

                        {/* Address */}
                        <div className="contact-detail">
                            <div className="icon-container" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={addressimg} alt="Address" />
                            </div>
                            <div className="text-container">
                                <p>Address</p>
                                <p>Door No. 1/190-C3, Ring Road Circle, Viraganur, Madurai, Tamil Nadu - 625009, India.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>






            {/* SUBSCRIBE */}
            {/* <div className="container subscribe_container">
    <div>
        <p className="subscribe_para">Subscribe to newsletter</p>
    </div>

    <div className="row banner paddingzero">
        <div className="col-md-6 col-sm-12 col-xs-12 paddingzero">
            <input type="text" className="form-control subscribe_input" placeholder="Enter your email" aria-label="Your placeholder text" value={email} onChange={handleInputChange} />
            {error && <div className="error-message">{error}</div>}
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 banner-img sub_button">
            <button className="subscribe_button" type="button" onClick={handleSubmit}>
                Subscribe
            </button>
        </div>
    </div>
</div> */}


            <div className="container subscribe_container">
                <div>
                    <p className="subscribe_para">Subscribe to newsletter</p>
                </div>

                <div className="row banner paddingzero d-flex align-items-center">
                    <div className="col-md-6 col-sm-12 col-xs-12 paddingzero d-flex inputpadding">
                        <input
                            type="text"
                            className="form-control subscribe_input"
                            placeholder="email"
                            aria-label="Your placeholder text"
                            value={email}
                            onChange={handleInputChange}
                        />
                        {error && <div className="error-message">{error}</div>}
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 banner-img sub_button d-flex justify-content-start">
                        <button className="subscribe_button" type="button" onClick={handleSubmit}>
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>





            {/* <div className="container subscribe_container">
                <div>
                    <p className="subscribe_para">Subscribe to newsletter for</p>
                    <p className="subscribe_para">getting updates</p>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8 p-0 d-flex">
                        <input type="text" className="form-control subscribe_input" placeholder="" aria-label="Your placeholder text" value={email} onChange={handleInputChange} />
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-0 d-flex">
                        <button className="subscribe_button" type="button" onClick={handleSubmit}>
                            Subscribe
                        </button>
                    </div>
                    {message && <div className="message">{message}</div>}
                </div>
            </div> */}



            <Footer />


        </div>

    );
};
export default Home;