import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl, Url } from './../../Api.js';
import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";

const AdminReview = () => {
   const { id } = useParams();  
   const [reviews, setReviews] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   useEffect(() => {
      if (id) {
         fetchReviews(id); 
      }
   }, [id]);

   const fetchReviews = async (vendorId) => {
    try {
       const postdata = { 'userid': vendorId };

       const response = await fetch(Url + "vendor-reviews", {
          method: "POST",
          headers: {
             "Content-Type": "application/json",
          },
          body: JSON.stringify(postdata),  
       });

       const data = await response.json();  

       console.log('Received data:', data);  

       if (data.success && Array.isArray(data.data)) {
          setReviews(data.data);  
       } else {
          setError("No reviews found for this vendor.");
       }
    } catch (err) {
       console.error('Error fetching reviews:', err);
       setError("Error fetching reviews.");
    } finally {
       setLoading(false); 
    }
 };

   return (
      <div id="app">
         <Header title="Reviews List" />
         <Sidebar activePage="Reviews" activeChildPage="List" />
         <div id="main">
            <Navbar />
            <div className="main-content container-fluid">
               <Pagetitle activePage="Reviews List" />
               <section className="section">
                  <div className="card">
                     <div className="card-header">
                        <span>Reviews</span>
                     </div>
                     <div className="card-body">
                        {loading ? (
                           <div>Loading reviews...</div>
                        ) : error ? (
                           <div className="error">{error}</div>
                        ) : reviews.length === 0 ? (
                           <div>No reviews found for this vendor.</div>
                        ) : (
                           <ul>
                              {reviews.map((review, index) => (
                                 <li key={index}>
                                    <strong>{review.customername}</strong> - {review.feedback}
                                    <div>Rating: {review.rating}</div>
                                    <div>{review.datetime}</div>
                                 </li>
                              ))}
                           </ul>
                        )}
                     </div>
                  </div>
               </section>
            </div>
         </div>
         <Footer />
      </div>
   );
};

export default AdminReview;
